export enum FeatureParams {
  FeatureStrictSystemTime = 'featureStrictSystemTime',
  FeatureNotifierTime = 'featureNotifierTime',
  FeatureNotifierTimeRepetition = 'featureNotifierTimeRepetitionInterval',
  FeatureNotifierEnabled = 'featureNotifierEnabled'
}

export interface Config {
  featureStrictSystemTime: boolean
  featureNotifierTime: number
  featureNotifierTimeRepetitionInterval: number,
  featureNotifierEnabled: boolean
}
