import { Id } from './common'
import { PatientIdEmbed } from './patient'
import { DeviceIdEmbed } from './device.model'

export interface UsageSessionId extends Id {}

export enum UsageSessionState {
  Active = 'ACTIVE',
  Done = 'DONE',
  Paused = 'PAUSED',
  Activating = 'ACTIVATING',
  Pausing = 'PAUSING',
  Resuming = 'RESUMING',
  Stopping = 'STOPPING'
}

export interface UsageSessionDTO extends UsageSessionId {
  _lastModifiedTime: string
  patient: PatientIdEmbed | null
  device: DeviceIdEmbed | null
  state: UsageSessionState
}

export interface UsageSession extends UsageSessionDTO {}
