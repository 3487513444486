import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { PccState } from './pcc.state'

@NgModule({
  declarations: [],
  providers: [],
  imports: [NgxsModule.forFeature([PccState])]
})
export class PccStoreModule {}
