import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { DepartmentState } from './department.state'

@NgModule({
  declarations: [],
  imports: [NgxsModule.forFeature([DepartmentState])],
  providers: []
})
export class DepartmentModule {}
