import {
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  model,
  Output
} from '@angular/core'
import { NzButtonComponent } from 'ng-zorro-antd/button'
import { NzColDirective, NzRowDirective } from 'ng-zorro-antd/grid'
import {
  NzFormControlComponent,
  NzFormDirective,
  NzFormItemComponent,
  NzFormLabelComponent
} from 'ng-zorro-antd/form'
import { NzIconDirective } from 'ng-zorro-antd/icon'
import { NzInputDirective } from 'ng-zorro-antd/input'
import { NzWaveDirective } from 'ng-zorro-antd/core/wave'
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import { RouterLink } from '@angular/router'
import { checkFormFields } from '../../../../../../core/helpers/functions'

interface LoginForm {
  username: FormControl<string>
  password: FormControl<string>
}

@Component({
  selector: 'aiomed-login-tablet-nurse',
  imports: [
    NzButtonComponent,
    NzColDirective,
    NzFormControlComponent,
    NzFormDirective,
    NzFormItemComponent,
    NzFormLabelComponent,
    NzIconDirective,
    NzInputDirective,
    NzRowDirective,
    NzWaveDirective,
    ReactiveFormsModule,
    RouterLink
  ],
  templateUrl: './login-tablet-nurse.component.html',
  styleUrl: './login-tablet-nurse.component.scss'
})
export class LoginTabletNurseComponent {
  @Output('loginWithPcc')
  loginWithPcc = new EventEmitter()
  @Output('formSubmitEmitter')
  formSubmit = new EventEmitter()
  @Output('onFocus') onFocus = new EventEmitter<void>()
  @Output('onBlur') onBlur = new EventEmitter<void>()
  @Output() loginWithCredEmitter = new EventEmitter()
  @Input() tab: string
  @Input() isLoading: boolean
  @Input() hasError: boolean = false
  @Input() showForgotPassword: boolean = true
  @Input() isMobile: boolean = false
  public isLoginWithCred = model(false)
  protected passwordVisible: boolean = false
  private readonly formBuilder = inject(FormBuilder)
  public loginForm: FormGroup<LoginForm> = this.formBuilder.nonNullable.group({
    username: [
      '',
      {
        validators: Validators.compose([Validators.required]),
        nonNullable: true
      }
    ],
    password: [
      '',
      {
        validators: Validators.compose([
          Validators.required,
          Validators.minLength(8)
        ]),
        nonNullable: true
      }
    ]
  })

  protected readonly resetFormOnNavigation = computed(() => {
    if (this.isLoginWithCred() === false) {
      this.loginForm.reset()
      this.loginForm.updateValueAndValidity()
    }
    return
  })

  handlerLoginWithCredClick() {
    this.isLoginWithCred.set(!this.isLoginWithCred())
    this.loginWithCredEmitter.emit(this.isLoginWithCred())
  }

  public submitForm(): void {
    if (this.loginForm.valid) {
      this.formSubmit.emit({
        ...this.loginForm.value,
        username: (this.loginForm.getRawValue().username || '').trim(),
        tab: this.tab
      })
      return
    }
    checkFormFields(this.loginForm)
  }
}
