import { AlertRuleDTO } from '../../shared/model/alert-rules.model'
import { cloneDeep } from 'lodash-es'
import { bodyTemperatureSetting } from './body-temperature-setting'

export const checkEmrRules = {
  body_temperature: cloneDeep(
    bodyTemperatureSetting.getBodyTemperatureErmRules()
  ),
  heart_rate: {
    min: 50,
    max: 100
  },
  respiration_rate: {
    min: 10,
    max: 20
  },
  spo2: {
    min: 92,
    max: 100
  },
  bloodGlucose: {
    min: 65,
    max: 160
  },
  diastolicPressure: {
    min: 50,
    max: 90
  },
  systolicPressure: {
    min: 90,
    max: 140
  },
  set: function (alertRule: AlertRuleDTO | null) {
    if (!alertRule) return
    this.body_temperature = cloneDeep(
      bodyTemperatureSetting.setBodyTemperatureErmRules(
        alertRule.bodyTemperature
      )
    )
    this.heart_rate = {
      min: alertRule.heartRate.minNormal,
      max: alertRule.heartRate.maxNormal
    }
    this.spo2 = {
      min: alertRule.spO2.minNormal,
      max: alertRule.spO2.maxNormal
    }
    this.respiration_rate = {
      min: alertRule.respirationRate.minNormal,
      max: alertRule.respirationRate.maxNormal
    }
    this.diastolicPressure = {
      min: alertRule.diastolicPressure.minNormal,
      max: alertRule.diastolicPressure.maxNormal
    }
    this.systolicPressure = {
      min: alertRule.systolicPressure.minNormal,
      max: alertRule.systolicPressure.maxNormal
    }
    this.bloodGlucose = {
      min: alertRule.bloodGlucose.minNormal,
      max: alertRule.bloodGlucose.maxNormal
    }
  },
  get: function (type: string) {
    if (type === 'body_temperature') {
      return this.body_temperature
    } else if (type === 'heart_rate') {
      return this.heart_rate
    } else if (type === 'respiration_rate') {
      return this.respiration_rate
    } else if (type === 'spo2') {
      return this.spo2
    } else if (type === 'diastolicPressure') {
      return this.diastolicPressure
    } else if (type === 'systolicPressure') {
      return this.systolicPressure
    } else if (type === 'bloodGlucose') {
      return this.bloodGlucose
    }
    return {
      min: 0,
      max: 0
    }
  }
}
