export const RESOLUTION_MODE_KEY = 'mode'

export function isForcedResolution(): boolean | undefined {
  const mode = localStorage.getItem(RESOLUTION_MODE_KEY)
  if (mode === 'tablet') {
    return true
  }
  if (mode === 'desktop') {
    return false
  }
  return undefined
}
