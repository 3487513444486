import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import moment from 'moment'
import 'moment-timezone'
import { environment } from '../../environments/environment'
import { PreferenceState } from '../../store/preference/preference.state'
import { ConfigService } from '../../shared/services/config.service'
import { FeatureParams } from '../../shared/model/config'

@Injectable()
export class CompareTimesInterceptor implements HttpInterceptor {
  constructor(
    private preferenceState: PreferenceState,
    private configService: ConfigService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const loginUrl = `${environment.measurementsApiUrl}/measurement/summary`
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse && req.url === loginUrl) {
          const isFeatureYEnabled = this.configService.isFeatureEnabled(
            FeatureParams.FeatureStrictSystemTime
          )
          const loginTimeFromHeaders = event.headers.get('Date')

          if (loginTimeFromHeaders) {
            const userTimezone =
              Intl.DateTimeFormat().resolvedOptions().timeZone
            const systemTime = moment()
            const loginTime = moment(loginTimeFromHeaders).tz(userTimezone)
            const offsetMinutes = systemTime.diff(loginTime, 'minutes')
            if (
              (offsetMinutes > 0 && isFeatureYEnabled) ||
              (offsetMinutes < 0 && isFeatureYEnabled)
            ) {
              this.preferenceState.setIncorrectTimeSystem(true)
            }
          }
        }
      })
    )
  }
}
