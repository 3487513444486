import { Injectable } from '@angular/core'
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs'
import { AuthState } from '../../store/auth/auth.state'
import { BackendService } from '../../shared/services/backend.service'

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authState: AuthState,
    private backendService: BackendService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token = this.authState.accessToken()
    if (req.url.indexOf('amazonaws') !== -1) {
      return next.handle(req)
    }
    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      })
    }
    return next.handle(req)
    // .pipe(takeUntil(this.backendService.logoutObservable))
  }
}
