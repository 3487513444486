import {
  DataAction,
  Payload,
  StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State, Store } from '@ngxs/store'
import { Injectable } from '@angular/core'
import { BackendService } from '../../shared/services/backend.service'
import {
  combineLatest,
  Observable,
  of,
  Subscription,
  switchMap,
  tap,
  timer
} from 'rxjs'
import { PatientState } from '../patient/patient.state'
import { PatientExportDTO } from '../../shared/model/pcc.model'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import { ExportAllInterface, ExportInterface } from '../../shared/model/export'
import { cloneDeep, orderBy, uniq, uniqBy } from 'lodash-es'
import { DepartmentState } from '../department/department.state'
import moment from 'moment/moment'
import { StoreEventsService } from '../store-events.service'
import { Changeable } from '../../shared/model/common'
import { DepartmentFilter } from '../../shared/model/departments.model'
import { getRandomInteger } from '../../core/helpers/get-random-integer'
import { getTodayDashedDate } from '../../core/helpers/get-today-dashed-day'
import { PreferenceState } from '../preference/preference.state'
import { checkFreshVitalCurrentShift } from '../../core/helpers/check-fresh-vital-current-shift'
import { UserState } from '../user/user.state'

export const exportFeatureName = 'export'

@StateRepository()
@State<ExportInterface>({
  name: exportFeatureName,
  defaults: {
    lastPatientsExport: [],
    allPatientsExport: [],
    totalMonthPatientsExports: [],
    totalWeekPatientsExports: []
  }
})
@Injectable()
export class ExportState extends NgxsDataRepository<ExportInterface> {
  private exportStateSubscription: Subscription
  private readonly isMobile = this.preferenceState.isMobile

  constructor(
    private backendService: BackendService,
    private actions: Actions,
    private patientState: PatientState,
    private departmentState: DepartmentState,
    private storeEvents: StoreEventsService,
    private preferenceState: PreferenceState,
    private readonly store: Store
  ) {
    super()
  }

  @Selector()
  public static patientsExports(state: ExportInterface): PatientExportDTO[] {
    return state.lastPatientsExport
  }

  @Selector()
  public static patientsAllExports(
    state: ExportInterface
  ): ExportAllInterface[] {
    return state.allPatientsExport
  }

  @Selector()
  public static totalMonthSuccessfulVitalsMinsSaved(
    state: ExportInterface
  ): number {
    const SECONDS_COEFFICIENT = 24
    const minsSaved =
      (state.totalMonthPatientsExports
        .filter(exp => exp.export_status === 'completed')
        .reduce((prev, next) => {
          const takenVitalsLength = Object.keys(next).filter(
            key =>
              (key === 'heartRate' ||
                key === 'bodyTemperature' ||
                key === 'bloodGlucose' ||
                key === 'spo2' ||
                key === 'respirationRate' ||
                key === 'systolicPressure') &&
              next[key]
          ).length

          prev += takenVitalsLength
          return prev
        }, 0) *
        SECONDS_COEFFICIENT) /
      60

    return +minsSaved.toFixed(1)
  }

  @Selector()
  public static totalWeekSuccessfulVitals(state: ExportInterface): number {
    return state.totalWeekPatientsExports
      .filter(exp => exp.export_status === 'completed')
      .reduce((prev, next) => {
        const takenVitalsLength = Object.keys(next).filter(
          key =>
            (key === 'heartRate' ||
              key === 'bodyTemperature' ||
              key === 'bloodGlucose' ||
              key === 'spo2' ||
              key === 'respirationRate' ||
              key === 'systolicPressure') &&
            next[key]
        ).length

        prev += takenVitalsLength
        return prev
      }, 0)
  }

  @Selector()
  public static averageSendingTime(): number {
    const averageSendingTimeValue = JSON.parse(
      localStorage.getItem('average_sending_time') || '{}'
    )
    if (
      averageSendingTimeValue &&
      averageSendingTimeValue['date'] &&
      averageSendingTimeValue['date'] === getTodayDashedDate()
    ) {
      return averageSendingTimeValue['value'] as unknown as number
    }
    const newValue = getRandomInteger(3, 15)
    localStorage.setItem(
      'average_sending_time',
      JSON.stringify({ value: newValue, date: getTodayDashedDate() })
    )
    return newValue
  }

  private static filterPatientsExportShift(data: PatientExportDTO[]) {
    const date = moment()
    if (date.get('hours') >= 7 && date.get('hours') < 15) {
      return data.filter(
        exports =>
          exports.creationTime >=
            moment(new Date())
              .set('hours', 7)
              .set('minutes', 0)
              .toISOString() &&
          exports.creationTime <
            moment(new Date()).set('hours', 15).set('minutes', 0).toISOString()
      )
    } else if (date.get('hours') >= 15 && date.get('hours') < 23) {
      return data.filter(
        exports =>
          exports.creationTime >=
            moment(new Date())
              .set('hours', 15)
              .set('minutes', 0)
              .toISOString() &&
          exports.creationTime <
            moment(new Date()).set('hours', 23).set('minutes', 0).toISOString()
      )
    }
    return data
  }

  @DataAction()
  addNewPatientsExports(
    @Payload('patientsExports') patientsExports: PatientExportDTO[]
  ) {
    uniq(patientsExports.map(e => e.observedPatient)).forEach(ex => {
      const patientExport = this.getState().allPatientsExport.find(
        e => e.patientId === ex
      )
      const idx = patientsExports.findIndex(pe => pe.observedPatient === ex)
      this.ctx.patchState({
        lastPatientsExport: [
          ...this.getState().lastPatientsExport.filter(
            e => e.observedPatient !== ex
          ),
          patientsExports[idx]
        ]
      })
      if (patientExport) {
        this.ctx.patchState({
          allPatientsExport: [
            ...this.getState().allPatientsExport.filter(
              e => e.patientId !== ex
            ),
            {
              ...patientExport,
              data: orderBy(
                [
                  ...ExportState.filterPatientsExportShift(patientExport.data),
                  patientsExports[idx]
                ],
                'creationTime',
                'asc'
              )
            }
          ]
        })
      } else {
        this.ctx.patchState({
          allPatientsExport: [
            ...this.getState().allPatientsExport.filter(
              e => e.patientId !== ex
            ),
            {
              patientId: patientsExports[idx].observedPatient,
              data: [patientsExports[idx]]
            }
          ]
        })
      }
    })
  }

  @DataAction()
  public refreshExport() {
    if (this.isMobile) {
      if (this.store.selectSnapshot(UserState.isUserCNA)) {
        this.patchState({ allPatientsExport: [], lastPatientsExport: [] })
      }
      return
    }
    let entities: any = cloneDeep(this.getState().allPatientsExport)
    entities = entities.filter(
      (d: ExportAllInterface) =>
        d.data &&
        d.data.length &&
        checkFreshVitalCurrentShift(d.data[d.data.length - 1].creationTime)
    )
    if (this.isMobile) {
      this.patchState({ allPatientsExport: [], lastPatientsExport: [] })
      return
    }
    this.patchState({ allPatientsExport: cloneDeep(entities) })
  }

  public exportShiftTimeUpdates$(): Observable<number> {
    return timer(10000, 10000).pipe(
      switchMap(n => {
        const allPatientsExport: ExportAllInterface[] = [
          ...cloneDeep(this.getState().allPatientsExport)
        ]
        this.patchState({ allPatientsExport: [] })
        this.patchState({ allPatientsExport })
        return of(n)
      })
    )
  }

  public updateWithModifiedExports(
    patientIds: string[],
    twoHoursLimit: boolean = false
  ) {
    return this.backendService
      .findAllPatientExports(patientIds, twoHoursLimit)
      .pipe(
        tap(res => {
          if (!res || !res.length) return
          uniq(res.map(e => e.observedPatient)).forEach(ex => {
            const data = res.filter(e => e.observedPatient === ex)
            this.setPatientExportsData(ex, data)
          })
        })
      )
  }

  public override ngxsOnInit() {
    this.storeEvents.patientsModifiedOrDepartmentChange$
      .pipe(
        tap(() => {
          if (this.exportStateSubscription)
            this.exportStateSubscription.unsubscribe()
          const department = !!this.departmentState.getState().currentDepartment
            ? this.departmentState.getState().currentDepartment
            : Object.values(this.departmentState.entities)[0]
          const patientIds = Object.values(
            this.patientState.getState().entities
          )
            .filter(p =>
              p.enabled &&
              p.emrid &&
              p.department &&
              department?.id !== DepartmentFilter.All
                ? p.department?.id === department?.id
                : p.department?.id
            )
            .map(p => p.id)

          this.exportStateSubscription = combineLatest([
            this.updateWithModifiedExports(
              patientIds,
              this.isMobile && !this.store.selectSnapshot(UserState.isUserCNA)
            ),
            this.exportShiftTimeUpdates$()
          ]).subscribe()

          if (this.isMobile) {
            return
          }

          this.getTotalWeekAndMonthPatientsExports(patientIds)
        })
      )
      .subscribe()

    this.storeEvents.departmentChange$
      .pipe(
        tap(() => {
          let patients = Object.values(this.patientState.entities)
          const department = Object.values(this.departmentState.entities).length
            ? Object.values(this.departmentState.entities)[0]
            : this.departmentState.getState().currentDepartment

          if (department && department.id !== DepartmentFilter.All) {
            patients = patients.filter(
              patient =>
                patient.department && patient.department.id === department.id
            )
          }

          this.getTotalWeekAndMonthPatientsExports(patients.map(p => p.id))
        })
      )
      .subscribe()

    this.storeEvents.logout$
      .pipe(
        tap(() => {
          this.ctx.patchState({
            lastPatientsExport: [],
            allPatientsExport: [],
            totalMonthPatientsExports: [],
            totalWeekPatientsExports: []
          })
          this.reset()
          if (this.exportStateSubscription)
            this.exportStateSubscription.unsubscribe()
        })
      )
      .subscribe()
  }

  @DataAction()
  insertFailedEMRTemporaryExport(
    @Payload('patientId') patientId: string,
    @Payload('data') data: PatientExportDTO
  ) {
    this.setPatientExportsData(patientId, [
      { ...data, export_status: 'failed', observedPatient: patientId }
    ])
  }

  @DataAction()
  getTotalMonthPatientsExports(@Payload('patientIds') patientIds: string[]) {
    const date = moment()
    const creationTime: string = date.startOf('month').hours(7).toISOString()
    return this.backendService
      .getPatientsExportsRecursively(patientIds, creationTime)
      .pipe(
        tap(res => {
          if (!res) {
            return
          }
          this.patchState({
            totalMonthPatientsExports: [
              ...this.getState().totalMonthPatientsExports,
              ...res.data
            ]
          })
        })
      )
  }

  @DataAction()
  getTotalWeekPatientsExports(@Payload('patientIds') patientIds: string[]) {
    const creationTime: string = moment()
      .subtract(7, 'days')
      .set('hours', 7)
      .set('minutes', 0)
      .set('seconds', 0)
      .set('milliseconds', 0)
      .toISOString()
    return this.backendService
      .getPatientsExportsRecursively(patientIds, creationTime)
      .pipe(
        tap(res => {
          if (!res) {
            return
          }
          this.patchState({
            totalWeekPatientsExports: [
              ...this.getState().totalWeekPatientsExports,
              ...res.data
            ]
          })
        })
      )
  }

  public getLastPatientExport(id: string) {
    return this.backendService.getLastPatientExport(id)
  }

  private setPatientExportsData(
    ex: string,
    data: (PatientExportDTO & Changeable)[]
  ): void {
    const ifObservedPatientExist = this.getState().allPatientsExport.findIndex(
      exp => exp.patientId === ex
    )
    let allPatientsExport
    if (ifObservedPatientExist === -1) {
      allPatientsExport = [
        ...this.getState().allPatientsExport,
        { patientId: data[0].observedPatient, data }
      ]
    } else {
      allPatientsExport = this.getState().allPatientsExport.map(exp =>
        exp.patientId === data[0].observedPatient
          ? {
              ...exp,
              data: uniqBy([...exp.data, ...data], obj => obj.id)
            }
          : exp
      )
    }
    this.ctx.patchState({
      lastPatientsExport: [
        ...this.getState().lastPatientsExport,
        data[data.length - 1]
      ],
      allPatientsExport
    })
  }

  private getTotalWeekAndMonthPatientsExports(patientIds: string[]): void {
    this.patchState({
      totalMonthPatientsExports: [],
      totalWeekPatientsExports: []
    })
    this.getTotalMonthPatientsExports(patientIds)
    this.getTotalWeekPatientsExports(patientIds)
  }
}
