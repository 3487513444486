import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { AlertState } from './alert.state'

@NgModule({
  declarations: [],
  providers: [],
  imports: [NgxsModule.forFeature([AlertState])]
})
export class AlertStoreModule {}
