import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MenuComponent } from './components/menu/menu.component'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { ButtonsModule } from '../UI/buttons/buttons.module'
import { AppRoutingModule } from '../../app-routing.module'
import { NzDrawerModule } from 'ng-zorro-antd/drawer'
import { NzMenuModule } from 'ng-zorro-antd/menu'
import { NzBadgeModule } from 'ng-zorro-antd/badge'
import { PatientStoreModule } from '../../../store/patient/patient-store.module'
import { NzLayoutModule } from 'ng-zorro-antd/layout'
import { CoreModule } from '../../../core/core.module'
import { ModalsModule } from '../modals/modals.module'
import { NzPopoverModule } from 'ng-zorro-antd/popover'
import { TranslateModule } from '@ngx-translate/core'
import { SideMenuComponent } from 'design-system'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { AuthModule } from '../../pages/auth/auth.module'
import { MobileIonicComponentsModule } from '../mobile-ionic-components/mobile-ionic-components.module'
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NzFormModule } from 'ng-zorro-antd/form'
import { NzInputNumberModule } from 'ng-zorro-antd/input-number'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { NzInputModule } from 'ng-zorro-antd/input'
import { LoginTabletNurseComponent } from '../../pages/auth/components/login/login-tablet-nurse/login-tablet-nurse.component'
import { ReceivingDataDirective } from './receiving-data.directive'

@NgModule({
  declarations: [MenuComponent],
  imports: [
    CommonModule,
    NzButtonModule,
    NzToolTipModule,
    NzIconModule,
    ButtonsModule,
    AppRoutingModule,
    NzDrawerModule,
    NzMenuModule,
    NzBadgeModule,
    PatientStoreModule,
    NzLayoutModule,
    CoreModule,
    ModalsModule,
    NzPopoverModule,
    TranslateModule,
    SideMenuComponent,
    NzModalModule,
    AuthModule,
    MobileIonicComponentsModule,
    NzCheckboxModule,
    ReactiveFormsModule,
    NzFormModule,
    NzInputModule,
    NzInputNumberModule,
    NzSelectModule,
    FormsModule,
    LoginTabletNurseComponent,
    ReceivingDataDirective
  ],
  exports: [MenuComponent]
})
export class MenuModule {}
