import { Id } from './common'
import { FileDTO, FileIdEmbed } from './file'
import { AlertDTO, AlertSeverity } from './alert'
import { ObservationFields, PatientObservationDTO } from './patient-observation'
import { AlertRuleDTO } from './alert-rules.model'
import { SortModel, SortType } from './sort.model'
import { orderBy } from 'lodash-es'
import { DepartmentDTO } from './permission.model'
import { PatientLogDTO } from './patient-log.model'
import { Metadata } from '@biot-client/biot-client-generic-entity'
import { MeasurementSummaryInterface } from './measurement'
import { TreatmentPlanDTO } from './treatment-plan'
import { CheckShiftSentEmrInformationInterface } from './report.model'
import { InsightDTO } from './insight.model'

export interface PatientId extends Id {}

export enum PatientGender {
  Female = 'FEMALE',
  Male = 'MALE',
  Undisclosed = 'UNDISCLOSED'
}

export enum PatientBPCuffSize {
  S = 's',
  M = 'm',
  L = 'l'
}

export interface PatientIdEmbed extends Id {
  name: string
}

export interface PatientCaregiverDTO {
  id: string
  name: string
  templateId?: string
}

export enum PatientSymptomFields {
  Weakness = 'Weakness',
  Nausea = 'Nausea',
  Tiredness = 'Tiredness',
  Pain = 'Pain',
  Fatigue = 'Fatigue',
  Depression = 'Depression',
  Choking = 'Choking',
  RacingHeartbeat = 'Racing heartbeat',
  ChestTightness = 'Chest tightness',
  ChestPressure = 'Chest pressure',
  ChestDiscomfort = 'Chest discomfort',
  ShortnessBreath = 'Shortness of breath',
  Cough = 'Cough',
  Heartburn = 'Heartburn',
  BlurringVision = 'Blurring of vision',
  MuscleContraction = 'Muscle contraction',
  Numbness = 'Numbness',
  Dizziness = 'Dizziness',
  Hyperglycemia = 'Hyperglycemia',
  Hypoglycemia = 'Hypoglycemia'
}

export interface PatientSymptomEnumItem {
  key: string
  label: string
}

export interface PatientBackend {
  data: PatientDTO[]
  metadata: Metadata
}

export interface PatientDTO extends PatientIdEmbed {
  alertRules: {
    id: string
    name: string
    templateId?: string
    templateName: string
  } | null
  templateAlertRuleId: string
  observations?: any
  emrid?: number
  firstName: string
  lastName: string
  nationalId: string
  dateOfBirth: Date | undefined
  gender: PatientGender
  room: string
  avatar: FileIdEmbed
  conditions: any[]
  phone?: string
  email: string
  alerts?: AlertDTO[]
  caregiver?: PatientCaregiverDTO
  creationTime: string
  admission_date?: string
  lastModifiedTime: string
  last_emr_update_time?: string
  symptoms: any[]
  department: DepartmentDTO
  activity: PatientLastObservation
  body_temperature: PatientLastObservation
  heart_rate: PatientLastObservation
  posture: PatientLastObservation
  respiration_rate: PatientLastObservation
  spo2: PatientLastObservation
  diastolicPressure: PatientLastObservation
  systolicPressure: PatientLastObservation
  exitBedRisk: PatientLastObservation
  bloodGlucose: PatientLastObservation
  cuffSize: PatientBPCuffSize | null
  nursingAssistant?: {
    id: string
  } | null
  enabled: boolean
}

export interface PatientSignsIllness {
  key: string
  label: string
  checked: boolean
  type: string
}

export enum PatientFilterItems {
  Archived = 'archived',
  All = 'all'
}

export interface PatientLastObservation {
  value: number
  timestamp: Date | string
}

export function patientsSort(
  sort: SortModel[],
  patients: PatientDTO[]
): PatientDTO[] {
  if (!sort.length) return patients
  const sortGender = sort.find(el => el.prop === SortType.Gender)
  const sortName = sort.find(el => el.prop === SortType.Name)
  const sortAge = sort.find(el => el.prop === SortType.DateOfBirth)
  if (sortGender && sortName) {
    patients = orderBy(
      patients,
      [SortType.Gender, SortType.Name],
      [sortGender.order, sortName.order]
    )
  } else if (sortGender && sortAge) {
    patients = orderBy(
      patients,
      [SortType.Gender, SortType.DateOfBirth],
      [sortGender.order, sortAge.order]
    )
  } else if (sortGender) {
    patients = orderBy(patients, [SortType.Gender], [sortGender.order])
  } else if (sortName) {
    patients = orderBy(patients, [SortType.Name], [sortName.order])
  } else if (sortAge) {
    patients = orderBy(patients, [SortType.DateOfBirth], [sortAge.order])
  }
  return patients
}

export interface PatientInterface extends PatientDTO {
  alerts: AlertDTO[]
  maxAlertSeverity: AlertSeverity | null
  avatar: FileIdEmbed | FileDTO | any
  defaultAlertRules: AlertRuleDTO | null
  patientAlertRules: AlertRuleDTO | null
  criticalObservation: any
  statusDetailInformation?: string
  symptoms: PatientSignsIllness[]
  conditions: PatientSignsIllness[]
  activeSignsIllness: PatientSignsIllness[]
  patientLog: PatientLogDTO[]
  unreadMessages: number
  hasMuteAlert: boolean
  checked?: boolean
  forceReadLoading?: boolean
  monitored?: boolean
  checkShiftSentEmrInformation?: CheckShiftSentEmrInformationInterface | null
  lastEMRUpdateTime?: string | null
  insights: InsightDTO[] | null
  hasBusinessRule?: boolean
  observations:
    | {
        [key in ObservationFields]: {
          lastUpdated: string
          isCritical?: boolean
          value: number | string
        }
      }
    | any
  reports: MeasurementSummaryInterface | null
  treatmentPlan: TreatmentPlanDTO[]
  lastObservationsTime: string | null
  measurement: PatientObservationDTO | null
  vitalsTakenGroup?: PatientVitalsTakenGroup
  requiredVitals: Partial<{ [key in ObservationFields]: boolean }>
  isReadyForReporting?: boolean
  hasSomeVitalsTaken: boolean
}

export enum PatientVitalsTakenGroup {
  VitalsRound,
  NotRequired,
  Recorded
}
