<ng-container *ngIf="isValueNumber || value === undefined; else groupNumber">
  <div
    class="lib-input-wrapper"
    [class.input-error]="hasInputError && !isCritical"
    [class.big]="isLarge"
    [class.no-editing]="disabledEditing"
    [class.error]="hasError"
    [class.critical-error]="
      (isCritical && !hasInputError) ||
      (hasInputError && isCritical && hasErrorType === 'device')
    "
    [attr.disabled]="isDisabled">
    <nz-input-number
      [class.big]="isLarge"
      tabindex="-1"
      [ngModel]="value"
      (ngModelChange)="onInputValueChange($event)"
      [nzFormatter]="nzFormatter"
      [nzParser]="nzParser"
      [nzMin]="minMaxStep?.nzMin || 0"
      [nzMax]="minMaxStep?.nzMax || Infinity"
      (nzFocus)="setCurrentFocusIndex(0)"
      (nzBlur)="onBlur.emit()"
      [nzDisabled]="disabledEditing"
      [class.old-value]="isOldValue"></nz-input-number>
  </div>
</ng-container>
<ng-template #groupNumber>
  <div
    class="lib-input-wrapper doubled"
    [class.input-error]="hasInputError && !isCritical"
    [class.big]="isLarge"
    [class.no-editing]="disabledEditing"
    [class.error]="hasError"
    [class.critical-error]="
      (isCritical && !hasInputError) ||
      (hasInputError && isCritical && hasErrorType === 'device')
    "
    [attr.disabled]="isDisabled">
    <ng-container
      *ngFor="
        let el of value | keyvalue: keepOrder;
        trackBy: trackBy;
        let index = index
      ">
      <span
        class="separator"
        [class.old-value]="isOldValue"
        *ngIf="index !== 0"
        [class.input-error]="hasInputError"
        [class.error]="hasError"
        >/</span
      >
      <nz-input-number
        [style.--_column-width]="
          (mainValue[el.key] ? mainValue[el.key] + '' : '').length
        "
        [class.big]="isLarge"
        tabindex="-1"
        [ngModel]="mainValue[el.key]"
        (ngModelChange)="onInputValueChange($event, el.key)"
        [nzMin]="minMaxStep?.[el.key]?.nzMin || 0"
        [nzMax]="minMaxStep?.[el.key]?.nzMax || Infinity"
        (nzFocus)="setCurrentFocusIndex(index)"
        (nzBlur)="onBlur.emit()"
        [nzDisabled]="disabledEditing"
        [class.old-value]="isOldValue"></nz-input-number>
    </ng-container>
  </div>
</ng-template>
@if (
  localShowRemeasure &&
  showRemeasure &&
  !isCritical &&
  (isDimmed || (hasInputError && hasErrorType === 'device'))
) {
  <span (click)="removeRemeasure()" class="remeasure">Remeasure</span>
}
@if (hasError && !(hasInputError && hasErrorType === 'device')) {
  <span
    class="lib-input__icon"
    nz-icon
    [nzType]="isCritical ? 'icons:alert-warning' : 'aio:input-warning'"></span>
} @else if (hasInputError) {
  <span
    class="lib-input__icon"
    nz-icon
    [nzType]="
      isCritical && hasErrorType === 'device'
        ? 'icons:alert-warning'
        : hasErrorType === 'device'
          ? 'aio:input-inaccuracy'
          : 'aio:input-invalid-entry'
    "></span>
}
