import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'shortName',
  standalone: true
})
export class ShortNamePipe implements PipeTransform {
  transform(name: string): string {
    return name
      .split(' ')
      .map(namePart => namePart[0])
      .join('')
      .toUpperCase()
      .slice(0, 2)
  }
}
