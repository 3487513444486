import { SearchResponseGenericEntityResponse } from '@biot-client/biot-client-generic-entity'
import { Entities, EntitiesByNames } from '../enums/entities.enums'

export function extractEntity(
  templateId: Entities,
  data: SearchResponseGenericEntityResponse
) {
  return data.data.filter(item => item._template?.id === templateId)
}

export function extractEntityByName(
  templateName: EntitiesByNames,
  data: SearchResponseGenericEntityResponse
) {
  return data.data.filter(item => item._template?.name === templateName)
}
