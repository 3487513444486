import {
  CollatableEntityCollections,
  CollatableEntityCollectionsRepository,
  defaultEntityCollation,
  EntityCollation
} from '../root-store-common'
import { AlertBackend, AlertDTO } from '../../shared/model/alert'
import {
  DataAction,
  Payload,
  StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State } from '@ngxs/store'
import {
  createEntityCollections,
  EntityDictionary
} from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import { EMPTY, Observable, tap } from 'rxjs'
import { PatientState } from '../patient/patient.state'
import { FileState } from '../file/file.state'
import { DeviceState } from '../device/device.state'
import { PatientDTO } from '../../shared/model/patient'
import { FileDTO } from '../../shared/model/file'
import { DeviceDTO } from '../../shared/model/device.model'
import { BackendService } from '../../shared/services/backend.service'
import { StoreEventsService } from '../store-events.service'

export const alertsBrowsingFeatureName = 'alertsBrowsing'

@StateRepository()
@State<CollatableEntityCollections<AlertDTO>>({
  name: alertsBrowsingFeatureName,
  defaults: {
    ...createEntityCollections(),
    ...defaultEntityCollation()
  }
})
@Injectable()
export class AlertsBrowsingState extends CollatableEntityCollectionsRepository<
  AlertDTO,
  EntityCollation
> {
  constructor(
    private backendService: BackendService,
    private patientState: PatientState,
    private actions: Actions,
    private storeEvents: StoreEventsService
  ) {
    super()
  }

  @Selector()
  public static isLoading(
    state: CollatableEntityCollections<AlertDTO>
  ): boolean {
    return state.isLoading
  }

  @Selector()
  public static totalCount(
    state: CollatableEntityCollections<AlertDTO>
  ): number {
    return state.totalCount
  }

  @Selector([PatientState.entities, FileState.files])
  public static alerts(
    state: CollatableEntityCollections<AlertDTO>,
    patients: CollatableEntityCollections<PatientDTO>,
    files: EntityDictionary<string, FileDTO>
  ): AlertDTO[] {
    const currentAlerts: AlertDTO[] = []
    Object.values(state.entities).forEach(alert => {
      if (alert.patient && alert.patient.id) {
        const patient: PatientDTO | undefined = Object.values(patients).find(
          p => p.id === alert.patient?.id
        )
        if (patient) {
          currentAlerts.push({
            ...alert,
            patient: {
              ...patient,
              avatar:
                patient.avatar &&
                files[patient.avatar.id] &&
                files[patient.avatar.id]?.signedUrl
                  ? files[patient.avatar.id]
                  : null
            }
          })
        }
      }
    })
    return currentAlerts
  }

  @Selector([PatientState.patient, FileState.files, DeviceState.allDevices])
  public static currenPatientAlerts(
    state: CollatableEntityCollections<AlertDTO>,
    patient: PatientDTO,
    files: EntityDictionary<string, FileDTO>,
    devices: DeviceDTO[]
  ): AlertDTO[] {
    if (!patient) return []
    let currentAlerts: AlertDTO[] = []
    Object.values(state.entities).forEach(alert => {
      if (
        alert.patient &&
        alert.patient.id &&
        alert.alertedDevice &&
        alert.alertedDevice.id
      ) {
        const device: DeviceDTO | undefined = devices.find(
          d => d.id === alert.alertedDevice?.id
        )
        if (patient && device) {
          currentAlerts.push({
            ...alert,
            alertedDevice: {
              ...device
            },
            patient: {
              ...patient,
              avatar:
                patient.avatar &&
                files[patient.avatar.id] &&
                files[patient.avatar.id]?.signedUrl
                  ? files[patient.avatar.id]
                  : null
            }
          })
        } else if (patient) {
          currentAlerts.push({
            ...alert,
            patient: {
              ...patient,
              avatar:
                patient.avatar &&
                files[patient.avatar.id] &&
                files[patient.avatar.id]?.signedUrl
                  ? files[patient.avatar.id]
                  : null
            }
          })
        } else if (device) {
          currentAlerts.push({
            ...alert,
            alertedDevice: {
              ...device
            }
          })
        }
      } else if (alert.patient && alert.patient.id) {
        if (patient) {
          currentAlerts.push({
            ...alert,
            patient: {
              ...patient,
              avatar:
                patient.avatar &&
                files[patient.avatar.id] &&
                files[patient.avatar.id]?.signedUrl
                  ? files[patient.avatar.id]
                  : null
            }
          })
        }
      } else if (alert.alertedDevice && alert.alertedDevice.id) {
        const device: DeviceDTO | undefined = devices.find(
          d => d.id === alert.alertedDevice?.id
        )
        if (device) {
          currentAlerts.push({
            ...alert,
            alertedDevice: {
              ...device
            }
          })
        }
      } else {
        currentAlerts.push({
          ...alert
        })
      }
    })

    // @ts-ignore
    return currentAlerts
  }

  public override ngxsOnInit() {
    this.storeEvents.logout$
      .pipe(
        tap(() => {
          this.reset()
        })
      )
      .subscribe()
  }

  @DataAction()
  public loadAllAlerts(
    @Payload('page') page: number,
    @Payload('freeTextFilter') freeTextSearch: string,
    @Payload('patientId') patientId?: string,
    @Payload('status') status?: string
  ) {
    this.ctx.patchState({
      isLoading: true
    })
    return this.backendService
      .getAllAlerts(
        page,
        freeTextSearch,
        patientId,
        status,
        this.patientState.ids
      )
      .pipe(
        tap((res: AlertBackend) => {
          const patientIds = res.data
            .map((patient: any) => patient.id)
            .filter((i: string | any) => i)
          this.patientState.loadPatientImages(patientIds)
          const alerts = Object.values(this.getState().entities)
          this.removeAll()
          this.upsertMany(page === 0 ? [...res.data] : [...alerts, ...res.data])
          this.ctx.patchState({ isLoading: false })
          if (freeTextSearch) return
          // this.patchState({
          // 	totalCount: res.metadata.page?.totalResults
          // })
        })
      )
  }

  @DataAction()
  public loadPatientAlerts(
    @Payload('page') page: number,
    @Payload('freeTextFilter') freeTextSearch: string,
    @Payload('patientId') patientId?: string,
    @Payload('status') status?: string
  ) {
    this.ctx.patchState({
      isLoading: true
    })
    return this.backendService
      .getAllAlerts(page, freeTextSearch, patientId, status)
      .pipe(
        tap((res: AlertBackend) => {
          const patientIds = res.data
            .map((patient: any) => patient.id)
            .filter((i: string | any) => i)
          this.patientState.loadPatientImages(patientIds)
          const alerts = Object.values(this.getState().entities)
          this.removeAll()
          this.upsertMany(page === 0 ? [...res.data] : [...alerts, ...res.data])
          this.ctx.patchState({ isLoading: false })
          if (freeTextSearch) return
          this.patchState({
            totalCount: res.metadata.page?.totalResults
          })
        })
      )
  }

  @DataAction()
  public loadAllAlertsInfo(
    @Payload('page') page: number,
    @Payload('freeTextFilter') freeTextSearch: string
  ) {
    return this.backendService
      .getAllAlerts(
        page,
        freeTextSearch,
        undefined,
        undefined,
        this.patientState.ids
      )
      .pipe(
        tap((res: AlertBackend) => {
          this.patchState({
            totalCount: res.metadata.page?.totalResults
          })
        })
      )
  }

  protected setPaginationSetting(): Observable<any> {
    return EMPTY
  }

  protected loadEntitiesFromBackend(
    ids: string[] | undefined
  ): Observable<void> {
    return EMPTY
  }
}
