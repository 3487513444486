<div class="item alerts-item" [attr.alert-key]="alertedItemKey">
  <div class="item-icon__container">
    <span
      nz-icon
      [nzTheme]="alertItems[alertedItemKey].iconTheme"
      [nzType]="alertItems[alertedItemKey].iconName"></span>
  </div>
  <div class="item-content">
    <h4 class="item-value">{{ value | number : '2.0' }}</h4>
    <h5 class="item-label">{{ alertItems[alertedItemKey].label }}</h5>
  </div>
</div>
