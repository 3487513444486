import { NgTemplateOutlet } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef
} from '@angular/core'
import { NzIconModule } from 'ng-zorro-antd/icon'

@Component({
  selector: 'aiomed-information-modal',
  imports: [NzIconModule, NgTemplateOutlet],
  templateUrl: './information-modal.component.html',
  styleUrl: './information-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[style.--top]': 'top',
    '[style.--width]': 'width',
    '[style.--z-index]': 'zIndex'
  }
})
export class InformationModalComponent {
  @Input() top: number = 171
  @Input() width: number = 483
  @Input() zIndex: number = 2
  @Input({ required: true }) title: string
  @Input({ required: true }) description: string
  @Input() iconTemplate: TemplateRef<unknown>
}
