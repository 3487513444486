<nz-table
  [nzData]="patients"
  [nzLoading]="isLoading"
  [nzShowPagination]="false"
  nzTableLayout="fixed">
  <thead>
    <tr>
      <th nzWidth="222px">Name</th>
      <th>Room</th>
      <th>Tasks</th>
      <th>HR</th>
      <th>RR</th>
      <th>BP (Sys/Dys)</th>
      <th>SpO2</th>
      <th>Temp</th>
      <th *ngIf="activeHour.type === 'time'" nzWidth="65px">Edit</th>
      <th *ngIf="activeHour.type !== 'time'" nzWidth="65px">EMR</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let patient of patients; trackBy: trackBy">
      <tr
        (click)="onNavigateToPatientById(patient.id)"
        [attr.severity]="patient.maxAlertSeverity">
        <td>
          <div class="name">
            <nz-avatar
              *ngIf="!patient.avatar; else patientAvatar"
              nzSize="small"
              [nzText]="patient.name | shortName"></nz-avatar>
            <ng-template #patientAvatar>
              <nz-avatar
                [nzSrc]="patient.avatar?.signedUrl"
                nzSize="small"></nz-avatar>
            </ng-template>
            <span class="name-value">{{
              patient.firstName + ' ' + patient.lastName
            }}</span>
          </div>
        </td>
        <td>
          <div>{{ patient.room }}</div>
        </td>
        <td>
          <div>{{ 4 }}</div>
        </td>
        <td>
          <div
            *ngIf="
              patient.reports?.measurements?.heartRate?.value;
              else emptyMeasurements
            "
            [class.alerted]="
              (patient.reports?.measurements?.heartRate)!.value
                | medicalCriticalSign
                  : (patient.patientAlertRules
                      ? patient.patientAlertRules.heartRate
                      : patient.defaultAlertRules?.heartRate)
            ">
            <lib-icon iconName="aio:manual-vitals-indicator" />
            {{
              (patient.reports?.measurements?.heartRate)!.value | roundNumber
            }}
          </div>
        </td>
        <td>
          <div
            *ngIf="
              patient.reports?.measurements?.respirationRate?.value;
              else emptyMeasurements
            "
            [class.alerted]="
              (patient.reports?.measurements?.respirationRate)!.value
                | medicalCriticalSign
                  : (patient.patientAlertRules
                      ? patient.patientAlertRules.respirationRate
                      : patient.defaultAlertRules?.respirationRate)
            ">
            <lib-icon iconName="aio:manual-vitals-indicator" />
            {{
              (patient.reports?.measurements?.respirationRate)!.value
                | roundNumber
            }}
          </div>
        </td>
        <td>
          <div
            *ngIf="
              patient.reports?.measurements?.systolicPressure?.value;
              else emptyMeasurements
            "
            [class.alerted]="
              ((patient.reports?.measurements?.systolicPressure)!.value
                | medicalCriticalSign
                  : (patient.patientAlertRules
                      ? patient.patientAlertRules.systolicPressure
                      : patient.defaultAlertRules?.systolicPressure)) ||
              ((patient.reports?.measurements?.diastolicPressure)!.value
                | medicalCriticalSign
                  : (patient.patientAlertRules
                      ? patient.patientAlertRules.diastolicPressure
                      : patient.defaultAlertRules?.diastolicPressure))
            ">
            <lib-icon iconName="aio:manual-vitals-indicator" />
            {{
              (patient.reports?.measurements?.systolicPressure)!.value
                | roundNumber
            }}
            <ng-container
              *ngIf="patient.reports?.measurements?.diastolicPressure?.value"
              >/</ng-container
            >
            {{
              (patient.reports?.measurements?.diastolicPressure)!.value
                | roundNumber
            }}
          </div>
        </td>
        <td>
          <div
            *ngIf="
              patient.reports?.measurements?.spo2?.value;
              else emptyMeasurements
            "
            [class.alerted]="
              (patient.reports?.measurements?.spo2)!.value
                | medicalCriticalSign
                  : (patient.patientAlertRules
                      ? patient.patientAlertRules.spO2
                      : patient.defaultAlertRules?.spO2)
            ">
            <lib-icon iconName="aio:manual-vitals-indicator" />
            {{ (patient.reports?.measurements?.spo2)!.value | roundNumber }} %
          </div>
        </td>
        <td>
          <div
            *ngIf="
              patient.reports?.measurements?.bodyTemperature?.value;
              else emptyMeasurements
            "
            [class.alerted]="
              (patient.reports?.measurements?.bodyTemperature)!.value
                | celsiusToFahrenheit
                | medicalCriticalSign
                  : (patient.patientAlertRules
                      ? patient.patientAlertRules.bodyTemperature
                      : patient.defaultAlertRules?.bodyTemperature)
                  : 'temp'
            ">
            <lib-icon
              *ngIf="patient.reports?.measurements?.bodyTemperature?.isManual"
              iconName="aio:manual-vitals-indicator" />
            {{
              (patient.reports?.measurements?.bodyTemperature)!.value
                | celsiusToFahrenheit
            }}
            {{
              (patient.reports?.measurements?.bodyTemperature)!.value
                | celsiusFahrenheitIcon
            }}
          </div>
        </td>
        <td>
          <div class="actions">
            <button (click)="onPatientEdit(patient, $event)">
              <lib-icon iconName="edit" iconTheme="outline" />
            </button>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</nz-table>

<ng-template #emptyMeasurements>
  <div>
    <lib-icon class="empty-measurements" iconName="aio:bad-reading" />
  </div>
</ng-template>
