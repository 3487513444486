import { Injectable } from '@angular/core'
import LogRocket from 'logrocket'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {
  public logrocketInit(version: string): void {
    LogRocket.init(environment.logrocketAppID, {
      release: version.replace(/\:/g, '-')
    })
  }
}
