import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { SessionState } from './session.state'

@NgModule({
  declarations: [],
  imports: [NgxsModule.forFeature([SessionState])],
  providers: []
})
export class SessionStoreModule {}
