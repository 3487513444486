import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  HostBinding,
  HostListener,
  inject,
  OnInit,
  signal,
  ViewChild,
  ViewContainerRef
} from '@angular/core'
import {
  combineLatest,
  debounce,
  delay,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  Observable,
  of,
  take,
  timer
} from 'rxjs'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { Select } from '@ngxs/store'
import { ErrorState } from '../store/error/error.state'
import { AuthState } from '../store/auth/auth.state'
import { PreferenceState } from '../store/preference/preference.state'
import { TranslateService } from '@ngx-translate/core'
import moment from 'moment/moment'
import { registerLocaleData } from '@angular/common'
import { UserState } from '../store/user/user.state'
import { UserInterface } from '../shared/model/user.model'
import {
  Event as NavigationEvent,
  NavigationStart,
  Router,
  RouterEvent
} from '@angular/router'
import { DepartmentState } from '../store/department/department.state'
import { NotificationState } from '../store/notification/notification.state'
import { NzModalService } from 'ng-zorro-antd/modal'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { routingAnimation } from '../shared/animations/routing-animation'
import { ScreenBlockedStatus } from '../shared/model/preference.model'
import { DeviceDetectorService } from 'ngx-device-detector'
import { AnimationOptions } from 'ngx-lottie'
import { NewVersionCheckerService } from '../shared/services/new-version-checker.service'
import { PccState } from '../store/pcc/pcc.state'
import { TestModeComponent } from './test-mode/test-mode.component'
import { environment } from '../environments/environment'
import { RootStore } from '../store/root-store'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { NetworkService } from '../shared/services/network.service'

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routingAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AppComponent implements OnInit, AfterViewInit {
  private readonly networkService = inject(NetworkService)
  readonly isOffline$ = this.networkService.isOnlineObs$.pipe(
    distinctUntilChanged(),
    debounce(isOnline => (isOnline ? of(0) : timer(5 * 60 * 1000))),
    map(isOnline => !isOnline)
  )
  protected readonly isOnline$ = this.networkService.isOnlineObs$

  @Select(ErrorState.errorMessage)
  errorMessage$: Observable<string | null>

  @Select(AuthState.isAuthenticated)
  isAuthorized$: Observable<boolean>
  @Select(UserState.userProfile)
  userProfile$: Observable<any>
  @Select(PreferenceState.language)
  language$: Observable<string>
  @Select(UserState.currentUser)
  user$: Observable<UserInterface | null>
  @Select(PreferenceState.isMfaRequired)
  isMfaRequired$: Observable<boolean>
  @Select(PreferenceState.sessionExpire)
  isSessionExpire$: Observable<boolean>
  @Select(PreferenceState.notification)
  notification$: Observable<boolean>
  @Select(PreferenceState.screenSaver)
  screenSaver$: Observable<boolean>
  @Select(PreferenceState.hasVirtualKeyboard)
  hasVirtualKeyboard$: Observable<boolean>
  @Select(AuthState.pccLoginSettingsTabletErrorShown)
  pccLoginSettingsTabletErrorShown$: Observable<boolean>
  @Select(AuthState.pccLoginWasNotPerformedButClosed)
  pccLoginWasNotPerformedButClosed$: Observable<boolean>
  @Select(PreferenceState.pccLoginWasPerformedWithCNAUser)
  pccLoginWasPerformedWithCNAUser$: Observable<boolean>
  @Select(RootStore.screenBlockedStatus)
  screenBlockedStatus$: Observable<string>
  @Select(PreferenceState.isForcedResolution)
  isForcedResolution$: Observable<boolean | undefined>
  @Select(PreferenceState.incorrectTimeSystem)
  incorrectTimeSystem$: Observable<boolean>
  @Select(PreferenceState.hasNotificationAccess)
  hasNotificationAccess$: Observable<boolean>
  isCollapseMenu: boolean = true
  isSessionExpired: boolean = false
  ScreenBlockedStatus = ScreenBlockedStatus
  options: AnimationOptions = {
    path: '/assets/animation.json'
  }
  public urlLogin$ = this.router.events.pipe(
    filter(
      (event: NavigationEvent | RouterEvent) => event instanceof NavigationStart
    ),
    // @ts-ignore
    map(e => e.url !== '/login')
  )
  @ViewChild('testModeContainer', { read: ViewContainerRef })
  testModeContainer!: ViewContainerRef
  isShowClockUpdateModal = false
  public pccLoginReturning = signal<boolean>(false)
  protected readonly pccLogouting$ = this.pccState.pccLogouting$
  protected pccLoginErrorTechnicalDetailsVisible: boolean = false
  protected readonly isMobile = this.preferenceState.isMobile
  @HostBinding('@routingAnimation') private routing: any

  constructor(
    private errorState: ErrorState,
    private notification: NzNotificationService,
    public preferenceState: PreferenceState,
    private translate: TranslateService,
    private notificationState: NotificationState,
    private modal: NzModalService,
    public router: Router,
    public authState: AuthState,
    private departmentState: DepartmentState,
    private deviceService: DeviceDetectorService,
    private readonly newVersionCheckerService: NewVersionCheckerService,
    private pccState: PccState,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    translate.setDefaultLang('en')
    this.controlDeviceSleepReturning()
    this.handleNativeDeviceVirtualKeyboardExistence()
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setWindowZoom()
  }

  ngOnInit(): void {
    this.initializeListeners()
    this.pccBroadcastChannelListener()
    this.setPccLoginReturningLoader()

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.authState.checkTokenStatus()
      }
    })
  }

  ngAfterViewInit() {
    if (environment?.test) {
      this.addTestModeComponent()
    }
  }

  addTestModeComponent() {
    this.testModeContainer.clear()
    this.testModeContainer.createComponent(TestModeComponent)
  }

  initializeListeners(): void {
    this.setMfaListenerSetting()
    this.errorMessageListenerSetting()
    this.languageListenerSetting()
    this.setNotificationPermissionSetting()
    this.setRouterConfigLogic()
    this.setWindowZoom()
    this.authState.checkTokenStatus()
  }

  setWindowZoom() {
    if (this.isMobile) return
    // @ts-ignore
    // document.body.style.zoom = window.innerWidth / 1920
  }

  errorMessageListenerSetting() {
    this.errorMessage$
      .pipe(untilDestroyed(this))
      .subscribe((message: string | null) => {
        if (!message) return
        this.notification.create('error', 'Error', message)
        setTimeout(() => this.errorState.clear(), 5000)
      })
  }

  setMfaListenerSetting() {
    this.isMfaRequired$.pipe(untilDestroyed(this)).subscribe(mfaRequired => {
      this.authState.patchState({
        mfaRequired
      })
    })
  }

  languageListenerSetting() {
    this.language$
      .pipe(untilDestroyed(this))
      .subscribe((data: string | null) => {
        if (data) {
          moment.locale(data)
          registerLocaleData(data)
          this.translate.use(data)
          return
        }
        this.preferenceState.setLanguage(
          // @ts-ignore
          navigator.language !== 'en' || navigator.language !== 'es'
            ? 'en'
            : navigator.language
        )
      })
  }

  setNotificationPermissionSetting() {
    combineLatest(this.userProfile$, this.notification$)
      .pipe(delay(2000), untilDestroyed(this), take(2))
      .subscribe(data => {
        let [user, notification] = [data[0], data[1]]
        if (!user) return
        if (typeof notification === 'object') {
          this.modal.confirm({
            nzTitle: 'Do you want to receive notification for alerts?',
            nzOnOk: () => {
              this.preferenceState.setNotification(true)
              this.setSoundNotificationsModalSetting()
            },
            nzOnCancel: () => {
              this.preferenceState.setNotification(false)
            }
          })
        }
      })

    combineLatest(this.userProfile$, this.notification$)
      .pipe(delay(2000), untilDestroyed(this))
      .subscribe(data => {
        let [user, notification] = [data[0], data[1]]
        if (!user) return
        if (typeof notification === 'boolean' && notification) {
          this.notificationState.requestPermission()
        }
      })
  }

  setSoundNotificationsModalSetting() {
    this.modal.confirm({
      nzTitle: 'Do you want to receive sound notifications for alerts?',
      nzOnOk: () => {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(stream => {
            this.preferenceState.setPreferenceSoundNotifications(true)
          })
          .catch(error =>
            this.preferenceState.setPreferenceSoundNotifications(false)
          )
      },
      nzOnCancel: () => {
        this.preferenceState.setPreferenceSoundNotifications(false)
      }
    })
  }

  handlerCollapseMenuEmitter($event: boolean): void {
    this.preferenceState.setPreferenceIsCollapseMenu($event)
  }

  setRouterConfigLogic() {
    if (this.isMobile) return
    // this.router.resetConfig([...MobileRoutes])
  }

  public screenSaverClick(event: Event): void {
    if (this.isMobile) {
      event.stopPropagation()
    }
  }

  public hidePccLoginSettingsTabletError(): void {
    this.authState.pccLoginSettingsTabletErrorShow(null)
  }

  public pccLoginWasNotPerformedButClosed(): void {
    this.authState.pccLoginWasNotPerformedButClosed(false)
    if (this.authState.accessToken()) {
      this.authState.handlePccLoginWindowClosing()
    }
  }

  public pccLoginWasPerformedWithCNAUserClose(): void {
    this.preferenceState.setPccLoginWasPerformedWithCNAUser(false)
  }

  public handlerLoginWithPcc(): void {
    this.authState.pccLoginWasNotPerformedButClosed(false)
    this.authState.loginWithPcc(
      !this.authState.accessToken() ? 'login-tablet' : 'tablet'
    )
  }

  hideModal() {
    this.preferenceState.setSessionExpire(false)
  }

  handlerScreenSaverSubmitEmitter() {
    this.preferenceState.setScreenSever(false)
  }

  handlerCloseClockUpdateModal($event: boolean) {
    this.preferenceState.setIncorrectTimeSystem($event)
  }

  private pccBroadcastChannelListener() {
    const bc = new BroadcastChannel('aiomed_pcc_channel')
    bc.postMessage('loggedToPCC')
  }

  private controlDeviceSleepReturning(): void {
    if (!this.isMobile) {
      return
    }

    let timeHidden = new Date()
    let timeVisible = new Date()
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        timeHidden = new Date()
      } else {
        timeVisible = new Date()
      }
      if (!this.authState.isAuthenticated()) {
        return
      }

      if (!document.hidden) {
        this.authState.checkTokenStatus()
      }
      const differenceInMinutes = Math.abs(
        moment(timeVisible).diff(moment(timeHidden), 'minutes')
      )
      const IDLE_TIME_MINUTES = 2
      const RN_IDLE_TIME = 20
      if (differenceInMinutes >= IDLE_TIME_MINUTES && !document.hidden) {
        this.authState.handleSleepModeReturning(differenceInMinutes)
      }
    })
  }

  private handleNativeDeviceVirtualKeyboardExistence(): void {
    if (!('virtualKeyboard' in navigator)) {
      return
    }

    fromEvent((navigator as any).virtualKeyboard, 'geometrychange')
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        // VirtualKeyboardGeometryChange
        this.preferenceState.setVirtualKeyboardPresence()
      })
  }

  private setPccLoginReturningLoader(): void {
    const urlParams = new URLSearchParams(document?.location?.search)
    const loading = urlParams.get('loading')

    if (!loading) {
      return
    }

    this.pccLoginReturning.set(true)
    setTimeout(() => {
      this.pccLoginReturning.set(false)
    }, 5000)
  }
}
