import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AlertComponent } from './components/alert/alert.component'
import { InputsModule } from '../UI/inputs/inputs.module'
import { ButtonsModule } from '../UI/buttons/buttons.module'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzRadioModule } from 'ng-zorro-antd/radio'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NzFormModule } from 'ng-zorro-antd/form'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzInputModule } from 'ng-zorro-antd/input'
import { UserPopoverComponent } from './components/user-popover/user-popover.component'
import { NzUploadModule } from 'ng-zorro-antd/upload'
import { CoreModule } from '../../../core/core.module'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox'
import { MomentModule } from 'ngx-moment'
import { NzInputNumberModule } from 'ng-zorro-antd/input-number'
import { TranslateModule } from '@ngx-translate/core'
import { NzSliderModule } from 'ng-zorro-antd/slider'
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker'
import { AlertMobileComponent } from './components/mobile/alert/alert.component'
import { PccPatientsComponent } from './components/pcc-patients/pcc-patients.component'
import { NzTableModule } from 'ng-zorro-antd/table'
import { EMRVitalsModalComponent } from './components/emr-vitals-modal/emr-vitals-modal.component'
import { NzSpinModule } from 'ng-zorro-antd/spin'
import { ReportManualEditModalComponent } from './components/report-manual-edit-modal/report-manual-edit-modal.component'
import { ReportManualEmrModalComponent } from './components/report-manual-emr-modal/report-manual-emr-modal.component'
import { NotificationComponent } from './components/notification/notification.component'
import { AssignNewDeviceComponent } from './components/assign-new-device/assign-new-device.component'
import { ReportIssueComponent } from './components/report-issue/report-issue.component'
import { DeviceAssigmentActionComponent } from './components/device-assigment-action/device-assigment-action.component'
import { AlertRuleUnsavedChangesComponent } from './components/alert-rule-unsaved-changes/alert-rule-unsaved-changes.component'
import { EmrAlertItemComponent } from 'design-system'
import { ReportReviewAbnormalVitalsComponent } from './components/report-review-abnormal-vitals/report-review-abnormal-vitals.component'
import { NzPopoverModule } from 'ng-zorro-antd/popover'
import { EmrHistoryTimeComponent } from './components/emr-history-time/emr-history-time.component'
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm'
import { MobileIonicComponentsModule } from '../mobile-ionic-components/mobile-ionic-components.module'
import { ReportReviewAbnormalPressureCellComponent } from './components/report-review-abnormal-vitals/report-review-abnormal-pressure-cell/report-review-abnormal-pressure-cell.component'
import { ReportReviewAbnormalCellComponent } from './components/report-review-abnormal-vitals/report-review-abnormal-cell/report-review-abnormal-cell.component'
import { ReportManualEmrModalPressureItemComponent } from './components/report-manual-emr-modal/report-manual-emr-modal-pressure-item/report-manual-emr-modal-pressure-item.component'
import { ReportManualEmrModalItemComponent } from './components/report-manual-emr-modal/report-manual-emr-modal-item/report-manual-emr-modal-item.component'
import { ReportManualEditModalItemComponent } from './components/report-manual-edit-modal/report-manual-edit-modal-item/report-manual-edit-modal-item.component'
import { ReportManualEditModalPressureItemComponent } from './components/report-manual-edit-modal/report-manual-edit-modal-pressure-item/report-manual-edit-modal-pressure-item.component'
import { CheckCriticalVitalPipe } from './components/report-review-abnormal-vitals/check-critical-vital.pipe'

@NgModule({
  declarations: [
    AlertComponent,
    UserPopoverComponent,
    AlertMobileComponent,
    PccPatientsComponent,
    EMRVitalsModalComponent,
    ReportManualEditModalComponent,
    ReportManualEmrModalComponent,
    NotificationComponent,
    AssignNewDeviceComponent,
    DeviceAssigmentActionComponent,
    ReportIssueComponent,
    AlertRuleUnsavedChangesComponent,
    ReportReviewAbnormalVitalsComponent,
    EmrHistoryTimeComponent,
    ReportReviewAbnormalPressureCellComponent,
    ReportReviewAbnormalCellComponent,
    ReportManualEmrModalPressureItemComponent,
    ReportManualEmrModalItemComponent,
    ReportManualEditModalPressureItemComponent,
    ReportManualEditModalItemComponent
  ],
  imports: [
    CommonModule,
    InputsModule,
    ButtonsModule,
    NzIconModule,
    NzRadioModule,
    FormsModule,
    NzFormModule,
    NzButtonModule,
    NzInputModule,
    NzUploadModule,
    CoreModule,
    NzModalModule,
    NzSelectModule,
    NzAvatarModule,
    NzCheckboxModule,
    MomentModule,
    NzInputNumberModule,
    TranslateModule,
    NzSliderModule,
    NzTimePickerModule,
    NzTableModule,
    NzSpinModule,
    ReactiveFormsModule,
    EmrAlertItemComponent,
    NzPopoverModule,
    NzPopconfirmModule,
    MobileIonicComponentsModule,
    CheckCriticalVitalPipe
  ],
  exports: [
    AlertComponent,
    UserPopoverComponent,
    AlertMobileComponent,
    PccPatientsComponent,
    EMRVitalsModalComponent,
    ReportManualEditModalComponent,
    ReportManualEmrModalComponent,
    NotificationComponent,
    DeviceAssigmentActionComponent,
    AssignNewDeviceComponent,
    ReportIssueComponent,
    AlertRuleUnsavedChangesComponent,
    ReportReviewAbnormalVitalsComponent,
    EmrHistoryTimeComponent,
    ReportReviewAbnormalPressureCellComponent,
    ReportReviewAbnormalCellComponent,
    ReportManualEmrModalPressureItemComponent,
    ReportManualEmrModalItemComponent,
    ReportManualEditModalPressureItemComponent,
    ReportManualEditModalItemComponent
  ]
})
export class ModalsModule {}
