<nz-avatar
  (nzError)="nzError.emit($event)"
  [nzShape]="nzShape"
  [nzSize]="nzSize"
  [nzGap]="nzGap"
  [nzText]="shortenText && nzText ? (nzText | shortName) : nzText"
  [nzSrc]="nzSrc"
  [nzSrcSet]="nzSrcSet"
  [nzAlt]="nzAlt || nzText"
  [nzIcon]="nzIcon"
  [style.color]="textColor"
  [style.background-color]="bgColor"></nz-avatar>
