export function getLatestTimestampFromMeasurements<
  T extends { [key: string]: { timestamp: string | Date } }
>(measurements: T) {
  return Object.values(measurements).reduce((prev, next) => {
    if (prev < next.timestamp) {
      prev = next.timestamp as string
    }
    return prev
  }, '')
}
