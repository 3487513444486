import {
  DataAction,
  Payload,
  StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State } from '@ngxs/store'
import { Injectable } from '@angular/core'
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  finalize,
  interval,
  mergeMap,
  Observable,
  of,
  Subscription,
  switchMap,
  take,
  tap,
  timer
} from 'rxjs'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import {
  PatientExportDTO,
  PccDTO,
  PccFacilityDTO,
  PccPatientDTO,
  PCCStatus,
  PccUpdateEmrMeasurement
} from '../../shared/model/pcc.model'
import { BackendService } from '../../shared/services/backend.service'
import { mapToVoid } from '@angular-ru/cdk/rxjs'
import { cloneDeep } from 'lodash-es'
import { PatientDTO } from '../../shared/model/patient'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { PatientState } from '../patient/patient.state'
import { NotificationService } from '../../shared/services/notification.service'
import { StoreEventsService } from '../store-events.service'
import { GenericEntityResponse } from '@biot-client/biot-client-generic-entity'
import { ExportState } from '../export/export.state'
import { PatientVitalsInterface } from '../../shared/model/report.model'
import { NetworkService } from '../../shared/services/network.service'

export const pccFeatureName = 'pcc'

@StateRepository()
@State<PccDTO>({
  name: pccFeatureName,
  defaults: {
    pccPatients: [],
    hasMore: true,
    tokenStatus: null,
    loading: false,
    pccFacilities: [],
    hasMoreFacilities: true,
    sentPatientVitalsToPcc: [],
    detailCurrentLoading: null
  }
})
@Injectable()
export class PccState extends NgxsDataRepository<PccDTO> {
  checkPccTokenStatusSubscription: Subscription
  public pccLogouting$ = new BehaviorSubject<boolean>(false)
  public pccLoggining$ = new BehaviorSubject<boolean>(false)
  public allSentSuccessfully$ = new BehaviorSubject<boolean>(false)
  public successfullySentPatientsLength$ = new BehaviorSubject<number>(0)
  private successfullySentToPccPatient$ =
    new BehaviorSubject<PatientDTO | null>(null)
  public readonly successfullySentToPccPatientObs$ =
    this.successfullySentToPccPatient$.asObservable()

  constructor(
    private backendService: BackendService,
    private notification: NzNotificationService,
    private patientState: PatientState,
    private ntfService: NotificationService,
    private exportState: ExportState,
    private actions: Actions,
    private storeEvents: StoreEventsService,
    private readonly networkService: NetworkService
  ) {
    super()
  }

  @Selector()
  public static pccPatients(state: PccDTO): PccPatientDTO[] {
    return state.pccPatients.map(p => ({
      ...p,
      check: false
    }))
  }

  @Selector()
  public static pccHasMorePatients(state: PccDTO): boolean {
    return state.hasMore
  }

  @Selector()
  public static pccSentPatientVitals(state: PccDTO): string[] {
    return state.sentPatientVitalsToPcc
  }

  @Selector()
  public static pccDetailCurrentLoading(
    state: PccDTO
  ): { id: string; status: boolean } | null {
    return state.detailCurrentLoading
  }

  @Selector()
  public static pccHasMoreFacilities(state: PccDTO): boolean {
    return state.hasMoreFacilities
  }

  @Selector()
  public static pccPatientLoading(state: PccDTO): boolean {
    return state.loading
  }

  @Selector()
  public static tokenStatus(state: PccDTO): string | null {
    return state.tokenStatus
  }

  @Selector()
  public static pccFacilities(state: PccDTO): PccFacilityDTO[] {
    return state.pccFacilities
  }

  public override ngxsOnInit() {
    this.storeEvents.userModified$
      .pipe(
        tap(() => {
          if (this.checkPccTokenStatusSubscription)
            this.checkPccTokenStatusSubscription.unsubscribe()
          this.checkPccTokenStatusSubscription = interval(600000)
            .pipe(switchMap(() => this.getPccIsAuthStatus()))
            .subscribe()
        })
      )
      .subscribe()

    this.storeEvents.logout$
      .pipe(
        tap(() => {
          this.reset()
          if (this.checkPccTokenStatusSubscription)
            this.checkPccTokenStatusSubscription.unsubscribe()
        })
      )
      .subscribe()
  }

  @DataAction()
  getPccPatientList(
    @Payload('page') page: number,
    @Payload('patientId') patientId: string,
    @Payload('patientName') patientName: string,
    @Payload('facilityId') facilityId: number | null
  ) {
    this.ctx.patchState({ loading: true })
    return this.backendService
      .getPccPatients(page, patientId, patientName, facilityId)
      .pipe(
        tap(({ pccPatients, hasMore }) => {
          const tmp = cloneDeep(this.ctx.getState().pccPatients)
          this.patchState({
            pccPatients: [...tmp, ...pccPatients],
            hasMore,
            loading: false
          })
        }),
        mapToVoid()
      )
  }

  @DataAction()
  getPccFacilities(@Payload('page') page: number) {
    return this.backendService.getPccFacilities(page).pipe(
      tap(({ pccFacilities, hasMoreFacilities }) => {
        const tmp = cloneDeep(this.ctx.getState().pccFacilities)
        this.patchState({
          pccFacilities: [...tmp, ...pccFacilities],
          hasMoreFacilities
        })
      }),
      mapToVoid()
    )
  }

  @DataAction()
  updatePccEmrMeasurement(
    @Payload('data') res: PccUpdateEmrMeasurement,
    @Payload('patients') patients?: PatientVitalsInterface[]
  ) {
    this.patchState({
      detailCurrentLoading: {
        id: res.observedPatient,
        status: true,
        isFailed: false
      }
    })

    return this.backendService.updatePccEmrMeasurement(res).pipe(
      mergeMap(data => {
        this.patientState.upsertOne(data.patient)

        if (data.exportStatus === PCCStatus.Completed && !patients) {
          this.patchState({
            sentPatientVitalsToPcc: [
              ...this.getState().sentPatientVitalsToPcc,
              data.patient.id
            ]
          })
          this.exportState.addNewPatientsExports([
            data.biotObservationExportEntity
          ])
        } else if (
          data.exportStatus === PCCStatus.Completed &&
          !!patients &&
          !this.checkHasVitalTPFilter(
            patients.find(
              p => p.id === data.patient.id
            ) as PatientVitalsInterface,
            res
          )
        ) {
          this.exportState.addNewPatientsExports([
            data.biotObservationExportEntity
          ])
          this.patchState({
            sentPatientVitalsToPcc: [
              ...this.getState().sentPatientVitalsToPcc,
              data.patient.id
            ]
          })
        }

        this.showNotificationByStatus(data)
        const isFailed = data.exportStatus !== PCCStatus.Completed
        this.patchState({
          detailCurrentLoading: {
            id: data?.patient?.id,
            status: false,
            isFailed
          }
        })

        return of(data)
      }),
      catchError(err => {
        this.ntfService.error(`Failed to update Emr Measurement`)
        this.patchState({
          detailCurrentLoading: {
            id: res.observedPatient,
            status: false,
            isFailed: true
          }
        })
        return of(err)
      }),
      mapToVoid()
    )
  }

  checkHasVitalTPFilter(
    p: PatientVitalsInterface,
    res: PccUpdateEmrMeasurement
  ): boolean {
    return [
      p.checkShiftSentEmrInformation &&
        p.checkShiftSentEmrInformation.hasOwnProperty('bp') &&
        !res.diastolicPressure,
      p.checkShiftSentEmrInformation &&
        p.checkShiftSentEmrInformation.hasOwnProperty('bp') &&
        !res.systolicPressure,
      p.checkShiftSentEmrInformation &&
        p.checkShiftSentEmrInformation.hasOwnProperty('spo2') &&
        !res.spo2,
      p.checkShiftSentEmrInformation &&
        p.checkShiftSentEmrInformation.hasOwnProperty('rr') &&
        !res.respirationRate,
      p.checkShiftSentEmrInformation &&
        p.checkShiftSentEmrInformation.hasOwnProperty('hr') &&
        !res.heartRate,
      p.checkShiftSentEmrInformation &&
        p.checkShiftSentEmrInformation.hasOwnProperty('bt') &&
        !res.bodyTemperature
    ].some(Boolean)
  }

  @DataAction()
  updatePccEmrMeasurementBulk(
    @Payload('data')
    data: {
      observations: PccUpdateEmrMeasurement[]
    },
    @Payload('allPatientsLength')
    allPatientsLength: number
  ) {
    return this.backendService.updatePccEmrMeasurementBulk(data).pipe(
      tap(
        ({
          result
        }: {
          result: {
            exportStatus: string
            biotObservationExportEntity: GenericEntityResponse
            patient: {
              _id: string
            }
          }[]
        }) => {
          const errorPatientRetort = result.filter(
            data => data.exportStatus === 'FAILED_EMR'
          )
          const successPatientRetort = result.filter(
            data => data.exportStatus !== 'FAILED_EMR'
          )
          this.patchState({
            sentPatientVitalsToPcc: result
              .filter(data => data.exportStatus !== 'FAILED_EMR')
              .map(data => data.patient._id)
          })
          if (errorPatientRetort.length > 0) {
            this.ntfService.error(
              // @ts-ignore
              `${errorPatientRetort.length} patients failed to be sent to the EMR`
            )
          }

          if (successPatientRetort.length > 0) {
            this.ntfService.success(
              // @ts-ignore
              `${successPatientRetort.length} patients have been reported to EMR`
            )
            this.exportState.addNewPatientsExports(
              successPatientRetort.map(res =>
                BackendService.toPatientExportDTO(
                  res.biotObservationExportEntity
                )
              )
            )
            if (allPatientsLength - successPatientRetort.length === 0) {
              if (!errorPatientRetort.length) {
                this.setSuccessfullySentPatientsLength(
                  successPatientRetort.length
                )
                this.setAllSentSuccessfully(true)
                timer(5000)
                  .pipe(take(1))
                  .subscribe(() => {
                    this.setAllSentSuccessfully(false)
                  })
              }
            }
          }
          this.patchState({ loading: false })
        }
      ),
      catchError(() => {
        this.ntfService.error(`Something went wrong`)
        this.patchState({ loading: false })
        return of()
      }),
      mapToVoid()
    )
  }

  @DataAction()
  setLoading(@Payload('loading') loading: boolean): void {
    this.patchState({ loading })
  }

  @DataAction()
  setAllSentSuccessfully(
    @Payload('allSentSuccessfully') allSentSuccessfully: boolean
  ): void {
    this.allSentSuccessfully$.next(allSentSuccessfully)
  }

  @DataAction()
  setSuccessfullySentPatientsLength(
    @Payload('successfullySentPatientsLength')
    successfullySentPatientsLength: number
  ): void {
    this.successfullySentPatientsLength$.next(successfullySentPatientsLength)
  }

  pccLogout(): Observable<void> {
    this.dispatch({ type: 'PCC LOGOUT' })
    this.pccLogouting$.next(true)
    return this.backendService
      .pccLogout()
      .pipe(finalize(() => this.pccLogouting$.next(false)))
  }

  // @DataAction()
  getPccIsAuthStatus(propagateError: boolean = false) {
    return this.backendService.getPccIsAuthStatus().pipe(
      tap(tokenStatus => {
        this.patchState({ tokenStatus })
      }),
      catchError(er => {
        console.error(er)
        if (!propagateError) {
          return EMPTY
        }
        throw(er)
      })
    )
  }

  public resetPccDetailLoading(): void {
    this.patchState({ detailCurrentLoading: null })
  }

  public resetPccFailed(): void {
    this.patchState({
      detailCurrentLoading: {
        ...this.snapshot.detailCurrentLoading!,
        isFailed: false
      }
    })
  }

  protected setPaginationSetting(): Observable<any> {
    return EMPTY
  }

  protected loadEntitiesFromBackend(
    ids: string[] | undefined
  ): Observable<void> {
    return EMPTY
  }

  private showNotificationByStatus(data: {
    exportStatus: string
    patient: PatientDTO
    biotObservationExportEntity?: PatientExportDTO
  }): void {
    if (data.exportStatus === PCCStatus.Completed) {
      this.successfullySentToPccPatient$.next(data.patient)
    } else if (data.exportStatus === PCCStatus.Timeout) {
      this.ntfService.error(`EMR session has expired`)
    } else if (
      data.exportStatus === PCCStatus.InProgress ||
      data.exportStatus === PCCStatus.Starting
    ) {
      this.ntfService.warning(
        `${data.exportStatus
          .split('_')
          .join(' ')
          .toLowerCase()} Reported to EMR`
      )
    } else if (
      data.exportStatus === PCCStatus.Aborted ||
      data.exportStatus === PCCStatus.FailedEMR
    ) {
      this.ntfService.error(
        `Error reporting ${
          data.patient.lastName + ', ' + data.patient.firstName[0]
        } vitals`
      )
      this.exportState.insertFailedEMRTemporaryExport(
        data.patient.id,
        data?.biotObservationExportEntity!
      )
    } else if (data.exportStatus === PCCStatus.FailedInvalidPatient) {
      this.ntfService.error(
        `Patient linkage error: Incorrect PointClickCare record`
      )
    } else if (data.exportStatus === PCCStatus.FailedNoEMRId) {
      this.ntfService.error(
        `Failed - ${data.patient.name} not found in PointClickCare system`
      )
    } else {
      this.ntfService.error(
        `${data.exportStatus
          .split('_')
          .join(' ')
          .toLowerCase()} Reported to EMR`
      )
    }
  }
}
