import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SpinnerComponent } from './spinner/spinner.component'
import { NzIconModule } from 'ng-zorro-antd/icon'

@NgModule({
  declarations: [SpinnerComponent],
  imports: [CommonModule, NzIconModule],
  exports: [SpinnerComponent]
})
export class SpinnersModule {}
