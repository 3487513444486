import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  Configuration as UmsConfiguration,
  ApiModule as UmsApiModule
} from '@biot-client/biot-client-ums'
import {
  Configuration as OrganizationConfiguration,
  ApiModule as OrganizationApiModule
} from '@biot-client/biot-client-organization'
import {
  Configuration as DeviceConfiguration,
  ApiModule as DeviceApiModule
} from '@biot-client/biot-client-device'
import {
  Configuration as FileConfiguration,
  ApiModule as FileApiModule
} from '@biot-client/biot-client-file'
import {
  Configuration as GenericEntityConfiguration,
  ApiModule as GenericEntityApiModule
} from '@biot-client/biot-client-generic-entity'
import {
  Configuration as MeasurementConfiguration,
  ApiModule as MeasurementApiModule
} from '@biot-client/biot-client-measurement'

import { environment } from '../../../environments/environment'

export function apiConfigFactoryUms() {
  return new UmsConfiguration({
    basePath: `${environment.apiUrl}/ums`
  })
}

export function apiConfigFactoryOrganization() {
  return new OrganizationConfiguration({
    basePath: `${environment.apiUrl}/organization`
  })
}

export function apiConfigFactoryDevice() {
  return new DeviceConfiguration({
    basePath: `${environment.apiUrl}/device`
  })
}

export function apiConfigFactoryFile() {
  return new FileConfiguration({
    basePath: `${environment.apiUrl}/file`
  })
}

export function apiConfigFactoryGenericEntity() {
  return new GenericEntityConfiguration({
    basePath: `${environment.apiUrl}/generic-entity`
  })
}

export function apiConfigFactoryMeasurementEntity() {
  return new MeasurementConfiguration({
    basePath: `${environment.apiUrl}/measurement`
  })
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    UmsApiModule.forRoot(apiConfigFactoryUms),
    OrganizationApiModule.forRoot(apiConfigFactoryOrganization),
    DeviceApiModule.forRoot(apiConfigFactoryDevice),
    FileApiModule.forRoot(apiConfigFactoryFile),
    GenericEntityApiModule.forRoot(apiConfigFactoryGenericEntity),
    MeasurementApiModule.forRoot(apiConfigFactoryMeasurementEntity)
  ],
  exports: [
    UmsApiModule,
    OrganizationApiModule,
    DeviceApiModule,
    FileApiModule,
    GenericEntityApiModule,
    MeasurementApiModule
  ]
})
export class BiotModule {}
