import {
  CollatableEntityCollections,
  CollatableEntityCollectionsRepository,
  defaultEntityCollation,
  EntityCollation
} from '../root-store-common'
import {
  DataAction,
  Payload,
  StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import {
  createEntityCollections,
  EntityDictionary
} from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import { ignoreElements, Observable, tap } from 'rxjs'
import { UsageSessionDTO } from '../../shared/model/usage-session'
import { BackendService } from '../../shared/services/backend.service'

export const usageSessionsFeatureName = 'usageSessions'

@StateRepository()
@State<CollatableEntityCollections<UsageSessionDTO>>({
  name: usageSessionsFeatureName,
  defaults: {
    ...createEntityCollections(),
    ...defaultEntityCollation()
  }
})
@Injectable()
export class SessionState extends CollatableEntityCollectionsRepository<
  UsageSessionDTO,
  EntityCollation
> {
  constructor(private backendService: BackendService) {
    super()
  }

  @Selector()
  public static usageSession(
    state: CollatableEntityCollections<UsageSessionDTO>
  ): EntityDictionary<string, UsageSessionDTO> {
    return state.entities
  }

  @DataAction()
  public loadDeviceUsageSessions(@Payload('deviceIds') deviceIds: string[]) {
    return this.backendService.getDevicesSessions(deviceIds).pipe(
      tap(r => this.upsertAllUsageSessions(r)),
      ignoreElements()
    )
  }

  protected setPaginationSetting(): Observable<any> {
    throw new Error('Method not implemented.')
  }

  protected loadEntitiesFromBackend(
    ids: string[] | undefined
  ): Observable<void> {
    throw new Error('Method not implemented.')
  }

  private upsertAllUsageSessions(r: UsageSessionDTO[]) {
    this.upsertMany(r)
  }
}
