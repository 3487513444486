import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { TaskBrowsingState } from './task-browsing.state'

@NgModule({
  declarations: [],
  providers: [],
  imports: [NgxsModule.forFeature([TaskBrowsingState])]
})
export class TaskBrowsingStoreModule {}
