import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MainMobileComponent } from './component/main-mobile/main-mobile.component'
import { IonicModule } from '@ionic/angular'
import { RouterLink, RouterLinkActive } from '@angular/router'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { CoreModule } from '../../../core/core.module'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { SearchMobileComponent } from './component/search-mobile/search-mobile.component'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { FormsModule } from '@angular/forms'
import { HeaderMobileComponent } from './component/header-mobile/header-mobile.component'
import { MobileMenuComponent } from './component/mobile-menu/mobile-menu.component'
import { ManualEnterModalComponent } from './component/manual-enter-modal/manual-enter-modal.component'
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { WarningModalComponent } from './component/warning-modal/warning-modal.component'
import { NzButtonModule } from 'ng-zorro-antd/button'

@NgModule({
  declarations: [
    MainMobileComponent,
    SearchMobileComponent,
    HeaderMobileComponent,
    MobileMenuComponent,
    ManualEnterModalComponent,
    WarningModalComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterLinkActive,
    RouterLink,
    NzIconModule,
    CoreModule,
    NzAvatarModule,
    NzSelectModule,
    FormsModule,
    NzCheckboxModule,
    NzModalModule,
    NzButtonModule
  ],
  exports: [
    MainMobileComponent,
    SearchMobileComponent,
    HeaderMobileComponent,
    MobileMenuComponent,
    ManualEnterModalComponent,
    WarningModalComponent
  ]
})
export class MobileIonicComponentsModule {}
