import { DepartmentDTO } from './permission.model'

export interface PreferenceInterface {
  departmentId: string | null
  isCollapseMenu: boolean
  soundNotifications: boolean | null
  screenSaver: boolean
  sessionExpire: boolean
  notification: boolean | null
  lg: string | null
  mfaRequired: boolean
  isIncorrectTimeSystem: boolean
  // needChooseDepartment: boolean
  department: DepartmentDTO | null
  deviceId: string | null
  secondaryDevicesIds: string[] | null
  absenceOfMeasurementsHintOpened: boolean
  hasVirtualKeyboard: boolean
  pccLoginWithCNAUserWasPerformed: boolean
  hasNotificationAccess: boolean
  isMobile: boolean
  deviceDirectConnection: boolean | null
}

export enum ScreenBlockedStatus {
  Blocked = 'blocked',
  RedBlocked = 'red-blocked',
  BlueBlocked = 'blue-blocked'
}
