import { NgModule } from '@angular/core'
import { NGXS_PLUGINS, NgxsModule } from '@ngxs/store'
import { NgxsDataPluginModule } from '@angular-ru/ngxs'
import { NgxsRouterPluginModule } from '@ngxs/router-plugin'
import { ErrorStoreModule } from './error/error-store.module'
import { UserStoreModule } from './user/user-store.module'
import { FileStoreModule } from './file/file-store.module'
import { AlertStoreModule } from './alert/alert-store.module'
import { DeviceStoreModule } from './device/device-store.module'
import { MeasurementStoreModule } from './measurement/measurement-store.module'
import { PatientStoreModule } from './patient/patient-store.module'
import { SessionStoreModule } from './session/session-store.module'
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin'
import { AuthState } from './auth/auth.state'
import { AlertRuleStoreModule } from './alert-rule/alert-rule-store.module'
import { NotificationStoreModule } from './notification/notification-store.module'
import { DepartmentModule } from './department/department.module'
import { PreferenceState } from './preference/preference.state'
import { RootStore } from './root-store'
import { SignsIllnessStoreModule } from './signsIllness/signsIllness-store.module'
import { PatientLogStoreModule } from './patient-log/patient-log-store.module'
import { AlertsBrowsingStoreModule } from './alerts-browsing/alerts-browsing-store.module'
import { BackendService } from '../shared/services/backend.service'
import { TaskStoreModule } from './task/task-store.module'
import { ShiftPlanerStoreModule } from './shift-planer/shift-planer-store.module'
import { PccStoreModule } from './pcc/pcc-store.module'
import { TaskBrowsingStoreModule } from './task-browsing/task-browsing-store.module'
import { ReportStoreModule } from './report/report-store.module'
import { ExportStoreModule } from './export/export-store.module'
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { environment } from '../environments/environment'
import { ArchivedPatientStoreModule } from './archived-patient/archived-patient-store.module'
import { LogRocketPlugin } from '../core/helpers/logrocket-ngxs.middleware'
import { TemplateAlertRuleStoreModule } from './template-alert-rule/template-alert-rule-store.module'
import { TreatmentPlanStoreModule } from './treatment-plan/treatment-plan-store.module'
import { StoreEventsService } from './store-events.service'
import { InsightBrowsingStoreModule } from './insight-browsing/insight-browsing.module'
import { InsightStoreModule } from './insight/insight-store.module'
import { InsightsStoreModule } from './insights/insights-store.module'

@NgModule({
  declarations: [],
  imports: [
    // The sequence of initialization is important - otherwise Auth tokens are not initialized right from local storage
    NgxsModule.forRoot([AuthState, PreferenceState, RootStore]),
    NgxsStoragePluginModule.forRoot({
      key: [
        'auth.accessJwt',
        'auth.refreshJwt',
        'auth.CNAAccessJwt',
        'auth.CNARefreshJwt',
        'preference.departmentId',
        'preference.soundNotifications',
        'preference.notification',
        'preference.lg',
        'preference.mfaRequired',
        'preference.needChooseDepartment',
        'preference.department',
        'preference.deviceId',
        'preference.secondaryDevicesIds',
        'preference.screenSaver',
        'preference.deviceDirectConnection'
      ]
    }),
    NgxsDataPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: true,
      collapsed: false
    }),
    AlertStoreModule,
    AlertsBrowsingStoreModule,
    DepartmentModule,
    PatientStoreModule,
    DeviceStoreModule,
    ErrorStoreModule,
    UserStoreModule,
    FileStoreModule,
    MeasurementStoreModule,
    SessionStoreModule,
    AlertRuleStoreModule,
    NotificationStoreModule,
    InsightStoreModule,
    InsightBrowsingStoreModule,
    InsightsStoreModule,
    SignsIllnessStoreModule,
    PatientLogStoreModule,
    TaskStoreModule,
    ShiftPlanerStoreModule,
    PccStoreModule,
    TaskBrowsingStoreModule,
    ReportStoreModule,
    ExportStoreModule,
    ArchivedPatientStoreModule,
    TemplateAlertRuleStoreModule,
    TreatmentPlanStoreModule
  ],
  exports: [],
  providers: [
    BackendService,
    {
      provide: NGXS_PLUGINS,
      useClass: LogRocketPlugin,
      multi: true
    },
    StoreEventsService
  ]
})
export class RootStoreModule {}
