import { inject } from '@angular/core'
import { RESOLUTION_MODE_KEY } from './is-forced-resolutions'
import { DeviceDetectorService } from 'ngx-device-detector'
import { isMobile } from '../../core/helpers/functions'

const userAgent =
  window.navigator.userAgent ||
  navigator.userAgent ||
  navigator.vendor ||
  (window as any)?.opera

export function isMobileFn() {
  const urlParams = new URLSearchParams(window.location.search)
  const deviceDetectorService = inject(DeviceDetectorService)
  if (urlParams) {
    const modeParam = urlParams.get(RESOLUTION_MODE_KEY)
    if (modeParam) {
      if (modeParam === 'remove') {
        localStorage.removeItem(RESOLUTION_MODE_KEY)
      } else {
        localStorage.setItem(RESOLUTION_MODE_KEY, modeParam)
      }
    }
  }
  const resolutionMode = localStorage.getItem(RESOLUTION_MODE_KEY)
  if (resolutionMode) {
    if (resolutionMode === 'tablet') {
      return true
    }
    if (resolutionMode === 'desktop') {
      return false
    }
  }
  return (
    !!(!deviceDetectorService.isDesktop(userAgent) && isMobile.any()) ||
    (!!userAgent.match(/Version\/[\d\.]+.*Safari/) && isMobile.any())
  )
}
