import { SEMIAUTOMATIC_DEPARTMENT_ALLOWED_ROUTES } from './variables'

export function checkSemiAutomaticDepartmentAllowedRoute(url: string) {
  return SEMIAUTOMATIC_DEPARTMENT_ALLOWED_ROUTES.some(route => {
    if (route === '/') {
      return route === url
    }
    return url.includes(route)
  })
}
