import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'age',
  standalone: true
})
export class AgePipe implements PipeTransform {
  transform(dob: Date): number {
    const diffMs = Date.now() - dob.getTime()
    const ageDate = new Date(diffMs)

    return Math.abs(ageDate.getUTCFullYear() - 1970)
  }
}
