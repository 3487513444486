// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.dev.biot.aiomed.com',
  measurementsApiUrl: 'https://dev-measurement-service-ptistqzjta-uk.a.run.app',
  pccIntegrationApiUrl:
    'https://dev-pointclickcare-integration-ptistqzjta-uk.a.run.app',
  biobeatIntegrationApiUrl:
    'https://dev-biobeat-integration-ptistqzjta-uk.a.run.app',
  tasksApiUrl: 'https://dev-task-service-ptistqzjta-uk.a.run.app',
  reportsApiUrl: 'https://dev-report-service-ptistqzjta-uk.a.run.app',
  authApiUrl: 'https://dev-auth-service-ptistqzjta-uk.a.run.app',
  forceReadWsUrl: 'wss://dev-biobeat-integration-ptistqzjta-uk.a.run.app',
  notificationApiUrl:
    'https://dev-notifications-service-ptistqzjta-uk.a.run.app',
  firebase: {
    apiKey: 'AIzaSyC8LkVSUOvbeFd9vXBXLtbzyIvQFWeFrXM',
    authDomain: 'aiomed-front.firebaseapp.com',
    projectId: 'aiomed-front',
    storageBucket: 'aiomed-front.appspot.com',
    messagingSenderId: '480577997390',
    appId: '1:480577997390:web:36623b85f379658306111f',
    measurementId: 'G-YRTDL8L995'
  },
  productFruitsTabletCode: 'nw6NkSiIlwzxhDpe',
  productFruitsCode: 'noNgNadTs2YFvlnp',
  logrocketAppID: 'focbyk/aiomed-web-front',
  crispChatWebsiteID: 'f6ad5765-756a-47df-905b-f7a814c918b6'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
