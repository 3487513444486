import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import { pinValidator } from './pin-validator'
import { NzIconDirective } from 'ng-zorro-antd/icon'
import { ScreenBlockedStatus } from '../../../../../shared/model/preference.model'
import {
  fromEvent,
  merge,
  Observable,
  of,
  skip,
  switchMap,
  tap,
  timer
} from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import moment from 'moment'
import { DOCUMENT } from '@angular/common'

@UntilDestroy()
@Component({
  selector: 'aiomed-screen-saver-modal',
  imports: [ReactiveFormsModule, NzIconDirective],
  templateUrl: './screen-saver-modal.component.html',
  styleUrl: './screen-saver-modal.component.scss'
})
export class ScreenSaverModalComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() isOnline: boolean = true
  @Input() isShowButton: boolean = true
  @Input() isMobile: boolean = false
  @Input() isAuthenticated: boolean
  @Input({ transform: (value: null | string): null | string => value || '' })
  screenBlockedStatus: string | null = ''
  @Output() submitEmitter = new EventEmitter()
  sourceClick$: Observable<Event> = fromEvent(window, 'click')
  sourceTouchmove$: Observable<Event> = fromEvent(window, 'mousemove')
  hardCodePassword = '1111'
  pinForm: FormGroup
  errorMessage: string | null = null
  isShowPreScreenSaverMode = true
  previousValues: string[] = []

  @ViewChild('pin0') pin0: ElementRef
  @ViewChild('pin1') pin1: ElementRef
  @ViewChild('pin2') pin2: ElementRef
  @ViewChild('pin3') pin3: ElementRef
  protected readonly ScreenBlockedStatus = ScreenBlockedStatus

  constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.handleScreenSaverAppearingWhenNonAuthenticated(changes)
  }

  ngOnInit(): void {
    this.setFormInitialSettings()
    this.setUserActionsListener().pipe(untilDestroyed(this)).subscribe()
  }

  ngAfterViewInit(): void {}

  focusInitialPin(): void {
    if (!this.isAuthenticated) {
      this.submitEmitter.emit(true)
      return
    }
    setTimeout(() => {
      if (this.pin0 && this.pin0.nativeElement) {
        this.pin0.nativeElement.focus()
      }
    }, 10)
  }

  onFocus(event: FocusEvent, index: number) {
    const input = event.target as HTMLInputElement
    this.previousValues[index] = input.value
    input.value = ''
  }

  onBlur(event: FocusEvent, index: number) {
    const input = event.target as HTMLInputElement
    if (!input.value) {
      input.value = this.previousValues[index]
    }
  }

  onInput(event: Event, index: number) {
    const input = event.target as HTMLInputElement
    const value = input.value

    if (/^[0-9]$/.test(value)) {
      this.pinForm.controls[`pin${index}`].setValue(value)
      input.value = '✲'
      this.moveFocus(index)
    } else {
      input.value = ''
    }

    if (Object.values(this.pinForm.value).filter(v => v !== '').length === 4) {
      setTimeout(() => {
        this.onSubmit()
      }, 0)
    }
  }

  onKeyDown(event: KeyboardEvent, index: number) {
    const input = event.target as HTMLInputElement

    if (event.key === 'Backspace' && !input.value) {
      this.moveFocusBack(index)
      this.pinForm.get(['pin' + index])?.setValue('')
      this.previousValues[index] = ''
    }
  }

  moveFocus(index: number) {
    if (index < 3) {
      // @ts-ignore
      const nextPin = this[`pin${index + 1}`].nativeElement
      nextPin.focus()
    }
  }

  moveFocusBack(index: number) {
    if (index > 0) {
      // @ts-ignore
      const prevPin = this[`pin${index - 1}`].nativeElement
      prevPin.focus()
    }
  }

  onSubmit() {
    this.errorMessage = null
    const pin0Control = this.pinForm.get('pin0')
    const pin1Control = this.pinForm.get('pin1')
    const pin2Control = this.pinForm.get('pin2')
    const pin3Control = this.pinForm.get('pin3')

    if (this.pinForm.invalid) {
      if (
        pin0Control?.hasError('required') &&
        pin1Control?.hasError('required') &&
        pin2Control?.hasError('required') &&
        pin3Control?.hasError('required')
      ) {
        this.errorMessage = 'You must enter a PIN code to proceed'
      } else if (this.pinForm.hasError('pinLength')) {
        this.errorMessage = 'PIN must be 4 digits long'
      }
    } else {
      const pin = `${this.pinForm.value.pin0}${this.pinForm.value.pin1}${this.pinForm.value.pin2}${this.pinForm.value.pin3}`
      if (pin === this.hardCodePassword) {
        this.submitEmitter.emit(true)
      } else {
        this.errorMessage = 'Incorrect PIN. Please try again'
      }
      this.changeDetectorRef.markForCheck()
    }
  }

  handlerKeydownSubmit(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.isShowButton) {
      this.onSubmit()
    }
  }

  private setFormInitialSettings() {
    this.pinForm = this.fb.group(
      {
        pin0: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
        pin1: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
        pin2: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
        pin3: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]]
      },
      { validators: pinValidator() }
    )
  }

  private setUserActionsListener() {
    return merge(this.sourceClick$, this.sourceTouchmove$).pipe(
      skip(1),
      switchMap(state => {
        this.isShowPreScreenSaverMode = false
        const timerTime = moment().add(5, 'minutes')
        return state ? timer(timerTime.toDate()) : of(null)
      }),
      tap(() => {
        this.isShowPreScreenSaverMode = true
        ;(this.document.activeElement as HTMLInputElement)?.blur()
      }),
      untilDestroyed(this)
    )
  }

  private handleScreenSaverAppearingWhenNonAuthenticated(
    changes: SimpleChanges
  ): void {
    if (changes['isAuthenticated']) {
      if (!this.isAuthenticated && !this.isShowPreScreenSaverMode) {
        queueMicrotask(() => {
          this.submitEmitter.emit()
        })
      }
    }
  }
}
