import { Id } from './common'
import { AlertDTO, AlertSeverity } from './alert'
import { ObservationFields } from './patient-observation'
import { SortModel, SortType } from './sort.model'
import { cloneDeep, orderBy } from 'lodash-es'
import { Metadata } from '@biot-client/biot-client-generic-entity'

export interface DeviceId extends Id {}

export interface DeviceIdEmbed extends Id {
  name: string
  manufacturerId?: string
}

export enum DeviceModality {
  BedMounted = 'bed_mounted',
  Implantable = 'implantable',
  SurfaceMounted = 'surface_mounted',
  WallMounted = 'wall_mounted',
  Wearable = 'wearable'
}

export enum DeviceCriticalStatus {
  NoConnection = 'No connection',
  BadReading = 'Bad reading',
  LowBattery = 'Low battery',
  NoBattery = 'No battery',
  NoConfiguration = 'Not calibrated',
  Monitor = 'Monitor'
}

export enum DeviceModel {
  BiobeatWatch = 'biobeat_watch',
  BodiTrak2 = 'boditrak2',
  Dexton6 = 'dexton6',
  LifeSignalsPatch = 'lifesignals_patch',
  LinetSafeSense = 'linet_safesense',
  OmronUS = 'omron_us',
  V9 = 'v9'
}

export enum DeviceNames {
  LOCAL = 'local'
}

export type DeviceDataUpdate = { [key in ObservationFields]: number } & {
  timestamp: Date
  deviceId: string
  context: string
}

export type DeviceType = 'V9' | 'EDAN' | 'other'

export interface DeviceUpdateMqttMessage {
  metadata: {
    timestamp: number
    deviceId: string
    context: string
  }
  data: { [key in ObservationFields]: number }
}

export interface DeviceBackend {
  data: DeviceDTO[]
  metadata: Metadata
}

export interface DeviceDTO extends DeviceIdEmbed {
  modality: DeviceModality
  model: DeviceModel
  department?: DepartmentDto
  serialNumber: string
  patient:
    | {
        id: string
        name: string
        room: string
      }
    | any
  isConnected: boolean
  forceReadEnabled: boolean
  alerts?: AlertDTO[]
  batteryLevel: number | null
  configuration: boolean
  statusInformation: string | null
  lastMeasurementTime: string | Date | null
  lastStatusUpdate: string | Date | null
  creationTime?: string
  _lastModifiedTime?: string
  status:
    | {
        last_update_time: Date
        bp_systolic: number
        bp_diastolic: number
        heart_rate: number
        spo2: number
      }
    | any
  room: string | null | undefined
  deviceType: DeviceType
  isDeviceV9: boolean
}

export interface DepartmentDto {
  id: string
  name: string
  templateId: string
}

export interface DeviceInterface extends DeviceDTO {
  alerts: AlertDTO[]
  maxAlertSeverity: AlertSeverity | null
  isTransmitting: boolean
  activeSession: any
  statusDetailInformation: string
  // patient?: PatientInterface
}

export interface DeviceStatusDetailInformation {
  noConnectionLength: number
  badReadingLength: number
  lowBatteryLength: number
  noBatteryLength: number
  status: boolean
}

export function deviceSort(
  sort: SortModel[],
  devices: DeviceInterface[]
): DeviceInterface[] {
  if (!sort.length) return devices
  const sortConnection = sort.find(el => el.prop === SortType.Connection)
  const otherSort = sort.find(el => el.prop != SortType.Connection)
  let tmpDevices: any = cloneDeep(devices)
  if (sortConnection) {
    if (sortConnection.order == 'asc') {
      // @ts-ignore
      tmpDevices = tmpDevices.sort(value => {
        return value.isConnected || value.isTransmitting ? -1 : 1
      })
    } else {
      tmpDevices = devices.sort(value => {
        return value.isConnected || value.isTransmitting ? 1 : -1
      })
    }
  } else if (otherSort) {
    tmpDevices = orderBy(devices, otherSort.prop, otherSort.order)
  }
  return tmpDevices
}

export interface MqttDeviceDTO {
  metadata: Metadata
  data: MqttDeviceData
}

export interface MqttDeviceMetadata {
  entityTypeName: string
  actionName: string
  status: string
  startTime: string
  endTime: string
  initiator: MqttDeviceInitiator
}

export interface MqttDeviceInitiator {
  subject: MqttDeviceSubject
  sourceIpAddress: string
}

export interface MqttDeviceSubject {
  id: string
}

export interface MqttDeviceData {
  entity: MqttDeviceEntity
  additionalData: MqttDeviceAdditionalData
}

export interface MqttDeviceEntity {
  _description: string
  _id: string
  _ownerOrganization: MqttDeviceOwnerOrganization
  _template: MqttDeviceTemplate
  _referencers: MqttDeviceReferencers
  serialNumber: string
  idleTimeout: any
  manufacturerId: string
  model: string
  department: MqttDeviceDepartment
  _configuration: MqttDeviceConfiguration
  _status: MqttDeviceStatus
  _patient: MqttDevicePatient
  _creationTime: string
  _lastModifiedTime: string
  _caption: string
}

export interface MqttDeviceOwnerOrganization {
  id: string
  templateId: string
  parentEntityId: any
  templateName: string
  name: string
}

export interface MqttDeviceTemplate {
  name: string
  displayName: string
  id: string
}

export interface MqttDeviceReferencers {
  'Usage Sessions to Device': MqttDeviceUsageSessionsToDevice
}

export interface MqttDeviceUsageSessionsToDevice {
  name: string
  displayName: string
  count: number
  attributeId: string
  templateId: string
  referrer: any
}

export interface MqttDeviceDepartment {
  id: string
  templateId: string
  parentEntityId: any
  templateName: string
  name: string
}

export interface MqttDeviceConfiguration {
  systole_rest: number
  heart_rate_rest: number
  diastole_rest: number
}

export interface MqttDeviceStatus {
  _fwVersion: any
  _hwVersion: any
  _certificateStatus: string
  _bootTime: any
  _connection: MqttDeviceConnection
  _operational: MqttDeviceOperational
  last_update_time: string
  bp_diastolic: number
  heart_rate: number
  last_measurement_time: string
  bp_systolic: number
  respiration_rate: number
  spo2: number
  temp: number
}

export interface MqttDeviceConnection {
  _connected: boolean
  _ipAddress: any
  _lastConnectedTime: any
}

export interface MqttDeviceOperational {
  _status: any
  _message: string
}

export interface MqttDevicePatient {
  id: string
  templateId: string
  parentEntityId: any
  templateName: string
  name: string
}

export interface MqttDeviceAdditionalData {
  oldEntity: MqttDeviceOldEntity
}

export interface MqttDeviceOldEntity {
  _description: string
  _id: string
  _ownerOrganization: MqttDeviceOwnerOrganization2
  _template: MqttDeviceTemplate2
  _referencers: MqttDeviceReferencers2
  serialNumber: string
  idleTimeout: any
  manufacturerId: string
  model: string
  department: MqttDeviceDepartment2
  _configuration: MqttDeviceConfiguration2
  _status: MqttDeviceStatus2
  _patient: MqttDevicePatient2
  _creationTime: string
  _lastModifiedTime: string
  _caption: string
}

export interface MqttDeviceOwnerOrganization2 {
  id: string
  templateId: string
  parentEntityId: any
  templateName: string
  name: string
}

export interface MqttDeviceTemplate2 {
  name: string
  displayName: string
  id: string
}

export interface MqttDeviceReferencers2 {
  'Usage Sessions to Device': MqttDeviceUsageSessionsToDevice2
}

export interface MqttDeviceUsageSessionsToDevice2 {
  name: string
  displayName: string
  count: number
  attributeId: string
  templateId: string
  referrer: any
}

export interface MqttDeviceDepartment2 {
  id: string
  templateId: string
  parentEntityId: any
  templateName: string
  name: string
}

export interface MqttDeviceConfiguration2 {
  systole_rest: number
  heart_rate_rest: number
  diastole_rest: number
}

export interface MqttDeviceStatus2 {
  _fwVersion: any
  _hwVersion: any
  _certificateStatus: string
  _bootTime: any
  _connection: MqttDeviceConnection2
  _operational: MqttDeviceOperational2
  last_update_time: string
  bp_diastolic: number
  heart_rate: number
  last_measurement_time: string
  bp_systolic: number
}

export interface MqttDeviceConnection2 {
  _connected: boolean
  _ipAddress: any
  _lastConnectedTime: any
}

export interface MqttDeviceOperational2 {
  _status: any
  _message: string
}

export interface MqttDevicePatient2 {
  id: string
  templateId: string
  parentEntityId: any
  templateName: string
  name: string
}

export interface MqttDeviceDTO {
  metadata: Metadata
  data: MqttDeviceData
}

export interface MqttDeviceMetadata {
  entityTypeName: string
  actionName: string
  status: string
  startTime: string
  endTime: string
  initiator: MqttDeviceInitiator
}

export interface MqttDeviceInitiator {
  subject: MqttDeviceSubject
  sourceIpAddress: string
}

export interface MqttDeviceSubject {
  id: string
}

export interface MqttDeviceData {
  entity: MqttDeviceEntity
  additionalData: MqttDeviceAdditionalData
}

export interface MqttDeviceEntity {
  _description: string
  _id: string
  _ownerOrganization: MqttDeviceOwnerOrganization
  _template: MqttDeviceTemplate
  _referencers: MqttDeviceReferencers
  serialNumber: string
  idleTimeout: any
  manufacturerId: string
  model: string
  department: MqttDeviceDepartment
  _configuration: MqttDeviceConfiguration
  _status: MqttDeviceStatus
  _patient: MqttDevicePatient
  _creationTime: string
  _lastModifiedTime: string
  _caption: string
}

export interface MqttDeviceOwnerOrganization {
  id: string
  templateId: string
  parentEntityId: any
  templateName: string
  name: string
}

export interface MqttDeviceTemplate {
  name: string
  displayName: string
  id: string
}

export interface MqttDeviceReferencers {
  'Usage Sessions to Device': MqttDeviceUsageSessionsToDevice
}

export interface MqttDeviceUsageSessionsToDevice {
  name: string
  displayName: string
  count: number
  attributeId: string
  templateId: string
  referrer: any
}

export interface MqttDeviceDepartment {
  id: string
  templateId: string
  parentEntityId: any
  templateName: string
  name: string
}

export interface MqttDeviceConfiguration {
  systole_rest: number
  heart_rate_rest: number
  diastole_rest: number
}

export interface MqttDeviceStatus {
  _fwVersion: any
  _hwVersion: any
  _certificateStatus: string
  _bootTime: any
  _connection: MqttDeviceConnection
  _operational: MqttDeviceOperational
  last_update_time: string
  bp_diastolic: number
  heart_rate: number
  last_measurement_time: string
  bp_systolic: number
  respiration_rate: number
  spo2: number
  body_temperature: number
}

export interface MqttDeviceConnection {
  _connected: boolean
  _ipAddress: any
  _lastConnectedTime: any
}

export interface MqttDeviceOperational {
  _status: any
  _message: string
}

export interface MqttDevicePatient {
  id: string
  templateId: string
  parentEntityId: any
  templateName: string
  name: string
}

export interface MqttDeviceAdditionalData {
  oldEntity: MqttDeviceOldEntity
}

export interface MqttDeviceOldEntity {
  _description: string
  _id: string
  _ownerOrganization: MqttDeviceOwnerOrganization2
  _template: MqttDeviceTemplate2
  _referencers: MqttDeviceReferencers2
  serialNumber: string
  idleTimeout: any
  manufacturerId: string
  model: string
  department: MqttDeviceDepartment2
  _configuration: MqttDeviceConfiguration2
  _status: MqttDeviceStatus2
  _patient: MqttDevicePatient2
  _creationTime: string
  _lastModifiedTime: string
  _caption: string
}

export interface MqttDeviceOwnerOrganization2 {
  id: string
  templateId: string
  parentEntityId: any
  templateName: string
  name: string
}

export interface MqttDeviceTemplate2 {
  name: string
  displayName: string
  id: string
}

export interface MqttDeviceReferencers2 {
  'Usage Sessions to Device': MqttDeviceUsageSessionsToDevice2
}

export interface MqttDeviceUsageSessionsToDevice2 {
  name: string
  displayName: string
  count: number
  attributeId: string
  templateId: string
  referrer: any
}

export interface MqttDeviceDepartment2 {
  id: string
  templateId: string
  parentEntityId: any
  templateName: string
  name: string
}

export interface MqttDeviceConfiguration2 {
  systole_rest: number
  heart_rate_rest: number
  diastole_rest: number
}

export interface MqttDeviceStatus2 {
  _fwVersion: any
  _hwVersion: any
  _certificateStatus: string
  _bootTime: any
  _connection: MqttDeviceConnection2
  _operational: MqttDeviceOperational2
  last_update_time: string
  bp_diastolic: number
  heart_rate: number
  last_measurement_time: string
  bp_systolic: number
}

export interface MqttDeviceConnection2 {
  _connected: boolean
  _ipAddress: any
  _lastConnectedTime: any
}

export interface MqttDeviceOperational2 {
  _status: any
  _message: string
}

export interface MqttDevicePatient2 {
  id: string
  templateId: string
  parentEntityId: any
  templateName: string
  name: string
}

export interface MqttDeviceDTO {
  metadata: Metadata
  data: MqttDeviceData
}
export interface MqttDeviceMetadata {
  entityTypeName: string
  actionName: string
  status: string
  startTime: string
  endTime: string
  initiator: MqttDeviceInitiator
}
export interface MqttDeviceInitiator {
  subject: MqttDeviceSubject
  sourceIpAddress: string
}
export interface MqttDeviceSubject {
  id: string
}
export interface MqttDeviceData {
  entity: MqttDeviceEntity
  additionalData: MqttDeviceAdditionalData
}
export interface MqttDeviceEntity {
  _description: string
  _id: string
  _ownerOrganization: MqttDeviceOwnerOrganization
  _template: MqttDeviceTemplate
  _referencers: MqttDeviceReferencers
  serialNumber: string
  idleTimeout: any
  manufacturerId: string
  model: string
  department: MqttDeviceDepartment
  _configuration: MqttDeviceConfiguration
  _status: MqttDeviceStatus
  _patient: MqttDevicePatient
  _creationTime: string
  _lastModifiedTime: string
  _caption: string
}
export interface MqttDeviceOwnerOrganization {
  id: string
  templateId: string
  parentEntityId: any
  templateName: string
  name: string
}
export interface MqttDeviceTemplate {
  name: string
  displayName: string
  id: string
}
export interface MqttDeviceReferencers {
  'Usage Sessions to Device': MqttDeviceUsageSessionsToDevice
}
export interface MqttDeviceUsageSessionsToDevice {
  name: string
  displayName: string
  count: number
  attributeId: string
  templateId: string
  referrer: any
}
export interface MqttDeviceDepartment {
  id: string
  templateId: string
  parentEntityId: any
  templateName: string
  name: string
}
export interface MqttDeviceConfiguration {
  systole_rest: number
  heart_rate_rest: number
  diastole_rest: number
}
export interface MqttDeviceStatus {
  _fwVersion: any
  _hwVersion: any
  _certificateStatus: string
  _bootTime: any
  _connection: MqttDeviceConnection
  _operational: MqttDeviceOperational
  last_update_time: string
  bp_diastolic: number
  heart_rate: number
  last_measurement_time: string
  bp_systolic: number
  respiration_rate: number
  spo2: number
  temp: number
}
export interface MqttDeviceConnection {
  _connected: boolean
  _ipAddress: any
  _lastConnectedTime: any
}
export interface MqttDeviceOperational {
  _status: any
  _message: string
}
export interface MqttDevicePatient {
  id: string
  templateId: string
  parentEntityId: any
  templateName: string
  name: string
}
export interface MqttDeviceAdditionalData {
  oldEntity: MqttDeviceOldEntity
}
export interface MqttDeviceOldEntity {
  _description: string
  _id: string
  _ownerOrganization: MqttDeviceOwnerOrganization2
  _template: MqttDeviceTemplate2
  _referencers: MqttDeviceReferencers2
  serialNumber: string
  idleTimeout: any
  manufacturerId: string
  model: string
  department: MqttDeviceDepartment2
  _configuration: MqttDeviceConfiguration2
  _status: MqttDeviceStatus2
  _patient: MqttDevicePatient2
  _creationTime: string
  _lastModifiedTime: string
  _caption: string
}
export interface MqttDeviceOwnerOrganization2 {
  id: string
  templateId: string
  parentEntityId: any
  templateName: string
  name: string
}
export interface MqttDeviceTemplate2 {
  name: string
  displayName: string
  id: string
}
export interface MqttDeviceReferencers2 {
  'Usage Sessions to Device': MqttDeviceUsageSessionsToDevice2
}
export interface MqttDeviceUsageSessionsToDevice2 {
  name: string
  displayName: string
  count: number
  attributeId: string
  templateId: string
  referrer: any
}
export interface MqttDeviceDepartment2 {
  id: string
  templateId: string
  parentEntityId: any
  templateName: string
  name: string
}
export interface MqttDeviceConfiguration2 {
  systole_rest: number
  heart_rate_rest: number
  diastole_rest: number
}
export interface MqttDeviceStatus2 {
  _fwVersion: any
  _hwVersion: any
  _certificateStatus: string
  _bootTime: any
  _connection: MqttDeviceConnection2
  _operational: MqttDeviceOperational2
  last_update_time: string
  bp_diastolic: number
  heart_rate: number
  last_measurement_time: string
  bp_systolic: number
}
export interface MqttDeviceConnection2 {
  _connected: boolean
  _ipAddress: any
  _lastConnectedTime: any
}
export interface MqttDeviceOperational2 {
  _status: any
  _message: string
}
export interface MqttDevicePatient2 {
  id: string
  templateId: string
  parentEntityId: any
  templateName: string
  name: string
}

export interface LocalDeviceData {
  data: MqttDeviceStatus
  metadata: {
    timestamp: Date
  }
}
