import { Id } from './common'
import {
  MeasurementSummarySubjectDataInterface,
  MeasurementSummarySubjectInterface
} from './measurement'

export interface PatientObservationId extends Id {}

export enum ObservationFields {
  Activity = 'activity',
  BodyTemperature = 'body_temperature',
  HeartRate = 'heart_rate',
  Posture = 'posture',
  RespirationRate = 'respiration_rate',
  SpO2 = 'spo2',
  DiastolicPressure = 'diastolicPressure',
  SystolicPressure = 'systolicPressure',
  ExitBedRisk = 'exitBedRisk',
  BloodGlucose = 'bloodGlucose',
  MonitoringStatus = 'monitoringStatus',
  PainLevel = 'painLevel'
}

export type ObservationFieldKeys = keyof typeof ObservationFields

export interface ObservationStats {
  timestamp: Date
  average: number | any
}

export enum ObservationType {
  Live = 'live',
  Historical = 'historical'
}

export interface ObservationEcg {
  data: string
  metadata: {
    frequency: number
  }
}

export interface ObservationField {
  activity: number
  body_temperature: number
  heart_rate: number
  posture: number
  respiration_rate: number
  spo2: number
  diastolicPressure: number
  systolicPressure: number
  exitBedRisk?: number
  bloodGlucose?: number
  painLevel?: number
  context?: string
  deviceId?: number
  patientId: string
  sessionId?: string
  skin_temperature?: number
  monitoringStatus?: number
  ecg: ObservationEcg
  timestamp: Date | string
  isManual?: boolean
}

export interface ObservationLatest {
  timestamp: Date | string
  value: number | any
  deviceId?: string
  isManual?: boolean
  checked?: boolean
}

export type ObservationsLatestMap = {
  [key in ObservationFields]?: ObservationLatest
}

export type ObservationsSortedMap = { [timestamp: number]: ObservationField }

export type MeasurementSummarySubjectInterfaceKeys =
  keyof MeasurementSummarySubjectInterface

export interface PatientObservationDTO extends PatientObservationId {
  // because we will keep one-to-one observation to the patient the id will be patient's id
  latest: ObservationField[]
  observations: ObservationsSortedMap
  observationsWithManual?: ObservationsSortedMap
  latestPerVital?: ObservationsLatestMap
  lastMeasurementTime?: string | Date
  lastPainLevelMeasurementTime?: string | Date
  lastMeasurementTimeWithoutPainLevel?: string | Date
  abnormalValues?: {
    [K in Partial<MeasurementSummarySubjectInterfaceKeys>]: MeasurementSummarySubjectDataInterface // abnormality
  }
}
