import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { PatientLogState } from './patient-log.state'

@NgModule({
  declarations: [],
  imports: [NgxsModule.forFeature([PatientLogState])],
  providers: []
})
export class PatientLogStoreModule {}
