import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { DecimalPipe } from '@angular/common'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { AlertItemKey } from '../types/alert-item.type'
import { ALERT_ITEMS } from '../constants/alert-items.constants'

@Component({
  selector: 'lib-alerts-item',
  imports: [NzIconModule, DecimalPipe],
  templateUrl: './alerts-item.component.html',
  styleUrls: ['./alerts-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertsItemComponent {
  @Input({ required: true }) alertedItemKey!: AlertItemKey
  @Input({ required: true }) value!: number
  public alertItems = ALERT_ITEMS
}
