import { checkShiftTime } from './check-shift-time'
import moment from 'moment/moment'

export function checkFreshVitalCurrentShift(lastUpdated: string) {
  const shiftName = checkShiftTime.getShiftByTime(new Date().toISOString())
  let val: string = ''

  if (shiftName === checkShiftTime.night_shift_name) {
    if (
      lastUpdated &&
      new Date(lastUpdated).getHours() < checkShiftTime.night_shift.max
    ) {
      val = moment()
        .subtract(1, 'days')
        .set('hours', checkShiftTime.night_shift.min)
        .set('minutes', 0)
        .set('seconds', 0)
        .set('milliseconds', 0)
        .toISOString()
    } else {
      val = moment()
        .set('hours', checkShiftTime.night_shift.min)
        .set('minutes', 0)
        .set('seconds', 0)
        .set('milliseconds', 0)
        .toISOString()
    }
  }
  if (shiftName === checkShiftTime.day_shift_name) {
    val = moment()
      .set('hours', checkShiftTime.day_shift.min)
      .set('minutes', 0)
      .set('seconds', 0)
      .set('milliseconds', 0)
      .toISOString()
  }
  if (shiftName === checkShiftTime.morning_shift_name) {
    val = moment()
      .set('hours', checkShiftTime.morning_shift.min)
      .set('minutes', 0)
      .set('seconds', 0)
      .set('milliseconds', 0)
      .toISOString()
  }
  return lastUpdated < val
}

export function isBeforeCurrentShift(lastUpdated: string) {
  const shiftName = checkShiftTime.getShiftByTime(new Date().toISOString())
  let val: string = ''

  if (shiftName === checkShiftTime.night_shift_name) {
    if (
      lastUpdated &&
      new Date(lastUpdated).getHours() < checkShiftTime.night_shift.max
    ) {
      val = moment()
        .subtract(1, 'days')
        .set('hours', checkShiftTime.night_shift.min)
        .set('minutes', 0)
        .set('seconds', 0)
        .set('milliseconds', 0)
        .toISOString()
    } else {
      val = moment()
        .set('hours', checkShiftTime.night_shift.min)
        .set('minutes', 0)
        .set('seconds', 0)
        .set('milliseconds', 0)
        .toISOString()
    }
  }
  if (shiftName === checkShiftTime.day_shift_name) {
    val = moment()
      .set('hours', checkShiftTime.day_shift.min)
      .set('minutes', 0)
      .set('seconds', 0)
      .set('milliseconds', 0)
      .toISOString()
  }
  if (shiftName === checkShiftTime.morning_shift_name) {
    val = moment()
      .set('hours', checkShiftTime.morning_shift.min)
      .set('minutes', 0)
      .set('seconds', 0)
      .set('milliseconds', 0)
      .toISOString()
  }
  return lastUpdated < val
}
