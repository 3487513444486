import {
  CollatableEntityCollections,
  CollatableEntityCollectionsRepository,
  defaultEntityCollation,
  EntityCollation
} from '../root-store-common'
import {
  DataAction,
  Payload,
  StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State } from '@ngxs/store'
import { createEntityCollections } from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import { EMPTY, Observable, Subscription, tap } from 'rxjs'
import { mapToVoid } from '@angular-ru/cdk/rxjs'
import {
  CreatePatientLog,
  PatientLogDTO,
  UpdatePatientLogInterface
} from '../../shared/model/patient-log.model'
import { BackendService } from '../../shared/services/backend.service'

export const patientLogFeatureName = 'patientLog'

@StateRepository()
@State<CollatableEntityCollections<PatientLogDTO>>({
  name: patientLogFeatureName,
  defaults: {
    ...createEntityCollections(),
    ...defaultEntityCollation()
  }
})
@Injectable()
export class PatientLogState extends CollatableEntityCollectionsRepository<
  PatientLogDTO,
  EntityCollation
> {
  subscriptionBackendUpdates$: Subscription

  constructor(
    private backendService: BackendService,
    private actions: Actions
  ) {
    super()
  }

  public get backendUpdates$(): Observable<void> {
    return this.backendService.subscribeAllPatientsLogs().pipe(
      tap(res => this.upsertOne(res)),
      mapToVoid()
    )
  }

  @Selector()
  public static patientLog(
    state: CollatableEntityCollections<PatientLogDTO>
  ): PatientLogDTO[] {
    return Object.values(state.entities)
  }

  public override ngxsOnInit() {
    // this.actions
    // 	.pipe(
    // 		filter(
    // 			(res) =>
    // 				(res.action.type === 'LOGGED IN' && res.status === 'SUCCESSFUL') ||
    // 				(res.action.type === 'LOGOUT' && res.status === 'SUCCESSFUL') ||
    // 				(res.action.type === 'REFRESH TOKEN' && res.status === 'SUCCESSFUL')
    // 		)
    // 	)
    // 	.subscribe((data) => {
    // 		if (data.action.type === 'LOGOUT') {
    // 			if (!this.subscriptionBackendUpdates$) return
    // 			this.subscriptionBackendUpdates$.unsubscribe()
    // 			return
    // 		}
    // 		if (this.subscriptionBackendUpdates$)
    // 			this.subscriptionBackendUpdates$.unsubscribe()
    //
    // 		// this.subscriptionBackendUpdates$ = this.backendUpdates$.subscribe()
    // 	})
  }

  @DataAction()
  public createPatientLog(@Payload('patientLog') patientLog: CreatePatientLog) {
    return this.backendService.addPatientLogEntity(patientLog).pipe(
      tap(res => {
        this.upsertOne(res)
      })
    )
  }

  @DataAction()
  public updatePatientLog(
    @Payload('entityId') id: string,
    @Payload('update') entityDiff: UpdatePatientLogInterface
  ): Observable<void> {
    return this.backendService
      .setPatientLogRead(id, entityDiff)
      .pipe(mapToVoid())
  }

  protected setPaginationSetting(): Observable<any> {
    throw new Error('Method not implemented.')
  }

  protected loadEntitiesFromBackend(): Observable<void> {
    return EMPTY
  }
}
