export interface PccDTO {
  pccPatients: PccPatientDTO[]
  hasMore: boolean
  loading: boolean
  tokenStatus: string | null
  pccFacilities: PccFacilityDTO[]
  hasMoreFacilities: boolean
  sentPatientVitalsToPcc: string[]
  detailCurrentLoading: {
    id: string
    status: boolean
    isFailed?: boolean
  } | null
}

export interface PccPatientDTO {
  firstName: string
  lastName: string
  middleName?: string
  roomDesc: string
  patientId: number
  birthDate: string
  gender: string
  check?: boolean
  bedDesc: string
  unitDesc: string
}

export enum PCCStatus {
  Completed = 'COMPLETED',
  Timeout = 'TIMEOUT',
  InProgress = 'IN_PROGRESS',
  Starting = 'STARTING',
  Aborted = 'ABORTED',
  FailedEMR = 'FAILED_EMR',
  FailedNoEMRId = 'FAILED_NO_EMR_ID',
  FailedInvalidPatient = 'FAILED_INVALID_PATIENT'
}

export interface PccFacilityDTO {
  facId: number
  facilityName: string
}

export interface PccUpdateEmrMeasurement {
  observedPatient: string
  emrId?: number | null
  bodyTemperature?: number
  spo2?: number
  diastolicPressure?: number
  heartRate?: number
  pain_level?: number
  bloodGlucose?: number
  respirationRate?: number
  systolicPressure?: number
  bloodPressureMethod?: string
  heartRateMethod?: string
  spo2Method?: string
  bodyTemperatureMethod?: string
  export_status?: string
}

export interface PatientExportDTO extends PccUpdateEmrMeasurement {
  id: string
  creationTime: string
  lastModifiedTime: string
}
