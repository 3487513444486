import { Id } from './common'
import { Metadata } from '@biot-client/biot-client-generic-entity'

export enum NotificationStatus {
  UnRead = 'unread',
  Read = 'read'
}

export enum NotificationFilterProps {
  Department = 'departmentId',
  Status = 'status'
}

export enum NotificationSortProps {
  Room = 'room',
  CreationTime = 'creationTime',
  Status = 'status',
  PatientName = 'patientName'
}

export function collectNotificationSubject({
  type,
  subject,
  patient
}: NotificationBackendDTO): NotificationSubject {
  switch (type) {
    case NotificationType.NewInsight:
      return { ...subject, templateName: NotificationType.NewInsight }
    case NotificationType.NewAdmissionReport:
      return {
        ...subject,
        templateName: NotificationType.NewAdmissionReport,
        id: patient.id
      }
    default:
      return { ...subject, templateName: 'Unknown', id: 'Unknown' }
  }
}

export enum NotificationType {
  NewInsight = 'NEW_INSIGHT',
  NewAdmissionReport = 'NEW_ADMISSION_REPORT'
}

export const LIMIT = 30

export interface NotificationInterface {
  data: NotificationDTO[]
  isLoading: boolean
  totalCount: number
  status: NotificationInfo
  currentQueryParams: NotificationQueryParams | null
}

export interface NotificationSubject {
  id: string
  templateId?: string
  templateName: string
}

export interface NotificationCheck {
  id: string
  subject: NotificationSubject
  isUnread: boolean
}

export interface NotificationDTO extends Id {
  firstName: string
  lastName: string
  patientId: string
  name: string
  room: string
  avatar: string | null
  department: string
  isUnread: boolean
  message: string
  status: string
  type: string
  creationTime: string
  subject: NotificationSubject
}

export interface NotificationBackendDTO extends Id {
  patient: NotificationPatient
  message: string
  status: string
  type: string
  creationTime: string
  subject: NotificationSubject
}

export interface NotificationPatient {
  id: string
  avatar: string | null
  name: {
    firstName: string
    lastName: string
  }
  room: string
  department: {
    id: string
    name: string
  }
}

export interface NotificationInfo {
  unread: number
  total: number
  read: number
}

export interface NotificationSort {
  prop:
    | NotificationSortProps.PatientName
    | NotificationSortProps.Status
    | NotificationSortProps.Room
    | NotificationSortProps.CreationTime
  order: 'ASC' | 'DESC'
}

export interface NotificationFilter {
  prop: NotificationFilterProps.Department | NotificationFilterProps.Status
  value: string
}

export interface NotificationQueryParams {
  sort?: NotificationSort[]
  freeTextSearch?: string
  filters?: NotificationFilter[]
  limit: number
  page: number
}

export interface NotificationBackend {
  data: NotificationDTO[]
  metadata: Metadata
}
