import {
  CollatableEntityCollections,
  CollatableEntityCollectionsRepository,
  defaultEntityCollation,
  EntityCollation
} from '../root-store-common'
import { StateRepository } from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State } from '@ngxs/store'
import { createEntityCollections } from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import { EMPTY, Observable, Subscription, tap } from 'rxjs'
import { InsightDTO } from '../../shared/model/insight.model'
import { BackendService } from '../../shared/services/backend.service'
import { StoreEventsService } from '../store-events.service'

export const insightFeatureName = 'insight'

@StateRepository()
@State<CollatableEntityCollections<InsightDTO>>({
  name: insightFeatureName,
  defaults: {
    ...createEntityCollections(),
    ...defaultEntityCollation()
  }
})
@Injectable()
export class InsightState extends CollatableEntityCollectionsRepository<
  InsightDTO,
  EntityCollation
> {
  subscriptionGetAllInsights$: Subscription
  subscriptionBackendUpdates$: Subscription
  private insightStateSubscription: Subscription

  constructor(
    private backendService: BackendService,
    private actions: Actions, // private patientState: PatientState
    private storeEvents: StoreEventsService
  ) {
    super()
  }

  // public get backendUpdates$(): Observable<void> {
  // 	this.subscriptionGetAllInsights$ = this.backendService
  // 		.getAllInsightsRecursively()
  // 		.subscribe((res) => {
  // 			if (!res) {
  // 				this.subscriptionGetAllInsights$.unsubscribe()
  // 				return
  // 			}
  // 			this.upsertMany(res.data)
  // 		})
  // 	return this.backendService.subscribeAllInsights().pipe(
  // 		takeUntil(this.backendService.destroy$),
  // 		tap((res) => {
  // 			this.upsertOne(res)
  // 		}),
  // 		ignoreElements()
  // 	)
  // }

  @Selector()
  public static isLoading(
    state: CollatableEntityCollections<InsightDTO>
  ): boolean {
    return state.isLoading
  }

  @Selector()
  public static insights(
    state: CollatableEntityCollections<InsightDTO>
  ): InsightDTO[] {
    return Object.values(state.entities)
  }

  @Selector()
  public static entities(state: CollatableEntityCollections<InsightDTO>) {
    return state.entities
  }

  @Selector()
  public static totalCount(
    state: CollatableEntityCollections<InsightDTO>
  ): number {
    return state.totalCount
  }

  updateWithModifiedInsights() {
    return this.backendService.findAllInsights().pipe(
      tap(insights => {
        if (insights) {
          this.upsertMany(insights as InsightDTO[])
        }
      })
    )
  }

  public override ngxsOnInit() {
    this.storeEvents.patientsModified$
      .pipe(
        tap(() => {
          if (this.insightStateSubscription)
            this.insightStateSubscription.unsubscribe()
          this.insightStateSubscription =
            this.updateWithModifiedInsights().subscribe()
        })
      )
      .subscribe()

    this.storeEvents.logout$
      .pipe(
        tap(() => {
          this.reset()
          if (this.insightStateSubscription)
            this.insightStateSubscription.unsubscribe()
        })
      )
      .subscribe()
  }

  protected setPaginationSetting(): Observable<any> {
    return EMPTY
  }

  protected loadEntitiesFromBackend(
    ids: string[] | undefined
  ): Observable<void> {
    return EMPTY
  }
}
