import { Component, Input, input } from '@angular/core'
import { AnimationOptions, LottieComponent } from 'ngx-lottie'
import { NgClass } from '@angular/common'

@Component({
  selector: 'aiomed-report-loader',
  imports: [LottieComponent, NgClass],
  templateUrl: './report-loader.component.html',
  styleUrl: './report-loader.component.scss'
})
export class ReportLoaderComponent {
  @Input() loader: boolean = false
  public isDark = input<boolean>(false)
  options: AnimationOptions = {
    path: '/assets/animations/loader.json'
  }

  ngOnInit(): void {}
}
