export enum DepartmentFilter {
  All = 'all'
}

export enum DepartmentType {
  Automatic,
  SemiAutomatic
}

export const defaultAllDepartment = { id: DepartmentFilter.All, name: 'All' }
