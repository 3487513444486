import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { Config } from '../model/config'
import type { JsonValue } from '@openfeature/js-sdk'
import { isEqual } from 'lodash-es'

@Injectable({
	providedIn: 'root'
})
export class ConfigService {
	private storageKey = 'aiomedConfig'
	private configSubject: BehaviorSubject<Config | null> =
		new BehaviorSubject<Config | null>(null)

	constructor() {
		const storedConfig = localStorage.getItem(this.storageKey)
		this.configSubject.next(
			storedConfig ? (JSON.parse(storedConfig) as Config) : null
		)
	}

	getConfig$(): Observable<Config | null> {
		return this.configSubject.asObservable()
	}

	getConfig(): Config | null {
		return this.configSubject.value
	}

	setConfig(data: { config: Config; configured: boolean }): void {
		if (
			(this.getConfig() && !data.configured) ||
			isEqual(data.config, this.getConfig())
		)
			return
		localStorage.setItem(this.storageKey, JSON.stringify(data.config))
		this.configSubject.next(data.config)
	}

	clearConfig(): void {
		localStorage.removeItem(this.storageKey)
		this.configSubject.next(null)
	}

	isFeatureEnabled(featureKey: keyof Config): boolean {
		const config = this.getConfig()
		return config ? !!config[featureKey] : false
	}

	getStringValue(key: keyof Config, defaultValue: string): string {
		const config = this.getConfig()
		return config && typeof config[key] === 'string'
			? String(config[key])
			: defaultValue
	}

	getNumberValue(key: keyof Config, defaultValue: number): number {
		const config = this.getConfig()
		return config && typeof config[key] === 'number'
			? Number(config[key])
			: defaultValue
	}

	getObjectValue<T extends JsonValue>(key: keyof Config, defaultValue: T): T {
		const config = this.getConfig()
		return config && typeof config[key] === 'object'
			? (config[key] as T)
			: defaultValue
	}
}
