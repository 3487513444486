export interface SortModel {
  prop: string
  order: OrderEnum
}

export type OrderEnum = 'asc' | 'desc'

export enum SortType {
  Name = 'name',
  Gender = 'gender',
  DateOfBirth = 'dateOfBirth',
  Connection = 'connection',
  LastMeasurementTime = 'lastMeasurementTime',
  LastStatusUpdateTime = 'lastStatusUpdate',
  PatientName = 'patient.name',
  PatientRoom = 'patient.room',
  SerialNumber = 'serialNumber',
  BatteryLevel = 'batteryLevel',
  StatusInformation = 'statusInformation'
}
