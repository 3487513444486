import {
  AfterViewInit,
  Directive,
  ElementRef,
  inject,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges
} from '@angular/core'

@Directive({
  selector: '[libApplyClassToAllInnerSpans]',
  standalone: true
})
export class ApplyClassToAllInnerSpansDirective implements AfterViewInit {
  private elRef = inject(ElementRef)
  private renderer = inject(Renderer2)

  @Input('libApplyClassToAllInnerSpans') libApplyClassToAllInnerSpans: string =
    'big'

  ngAfterViewInit(): void {
    this.applyClass()
  }

  private applyClass(): void {
    const existingElement = this.elRef.nativeElement.querySelector(
      '.ant-checkbox-wrapper'
    )
    const spans = this.elRef.nativeElement.querySelectorAll('span')
    if (existingElement) {
      this.renderer.addClass(existingElement, this.libApplyClassToAllInnerSpans)
    }

    if (spans) {
      spans.forEach((checkboxElement: HTMLSpanElement) =>
        this.renderer.addClass(
          checkboxElement,
          this.libApplyClassToAllInnerSpans
        )
      )
    }
  }
}
