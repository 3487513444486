import {
  CollatableEntityCollections,
  CollatableEntityCollectionsRepository,
  defaultEntityCollation,
  EntityCollation
} from '../root-store-common'
import {
  DataAction,
  Payload,
  StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import {
  createEntityCollections,
  EntityDictionary
} from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import {
  bufferTime,
  concatMap,
  EMPTY,
  filter,
  ignoreElements,
  Observable,
  Subject,
  Subscription,
  tap
} from 'rxjs'
import { FileDTO } from '../../shared/model/file'
import { BackendService } from '../../shared/services/backend.service'
import { StoreEventsService } from '../store-events.service'
import moment from 'moment'

export const fileFeatureName = 'file'

@StateRepository()
@State<CollatableEntityCollections<FileDTO>>({
  name: fileFeatureName,
  defaults: {
    ...createEntityCollections(),
    ...defaultEntityCollation()
  }
})
@Injectable()
export class FileState extends CollatableEntityCollectionsRepository<
  FileDTO,
  EntityCollation
> {
  loadProcessFileSubscription$: Subscription
  private fileUpdatesSubscription: Subscription
  private avatarIdSubject = new Subject()

  constructor(
    private backendService: BackendService,
    private storeEvents: StoreEventsService
  ) {
    super()
  }

  @Selector()
  public static files(
    state: CollatableEntityCollections<FileDTO>
  ): EntityDictionary<string, FileDTO> {
    return state.entities
  }

  @Selector()
  public static pccPatientImage(
    state: CollatableEntityCollections<FileDTO>
  ): Blob | null {
    return state.pccCurrentPatientImage
  }

  @Selector()
  public static createdFileId(
    state: CollatableEntityCollections<FileDTO>
  ): string | null {
    return state.newFileId
  }

  requestAvatar(avatarId: string) {
    this.avatarIdSubject.next(avatarId)
  }

  public override ngxsOnInit() {
    this.storeEvents.loggedInAndRefreshToken$
      .pipe(
        tap(() => {
          if (this.fileUpdatesSubscription) {
            this.fileUpdatesSubscription.unsubscribe()
          }
          this.fileUpdatesSubscription =
            this.checkExpiredImageAndUpdate().subscribe()
          this.loadAvatarsListener()
        })
      )
      .subscribe()

    this.storeEvents.logout$
      .pipe(
        tap(() => {
          this.reset()
          if (this.fileUpdatesSubscription) {
            this.fileUpdatesSubscription.unsubscribe()
          }
          if (this.loadProcessFileSubscription$) {
            this.loadProcessFileSubscription$.unsubscribe()
          }
        })
      )
      .subscribe()
  }

  loadAvatarsListener() {
    if (this.loadProcessFileSubscription$) {
      this.loadProcessFileSubscription$.unsubscribe()
    }
    this.loadProcessFileSubscription$ = this.avatarIdSubject
      .pipe(
        bufferTime(3000, null, 10),
        filter(ids => Array.isArray(ids) && ids.length > 0),
        concatMap(ids => ids),
        concatMap(id => {
          if (Object.values(this.getState().entities).find(i => i.id === id)) {
            return EMPTY
          }
          return this.backendService.getFile(id as string)
        }),
        filter(avatar => avatar != null)
      )
      .subscribe({
        next: file => {
          const expiresValue = file.signedUrl
            .split('Expires')[1]
            .split('&')[0]
            .split('=')[1]
          const expiredTime = moment()
            .add(expiresValue, 'seconds')
            .toISOString()
          this.upsertOne({ ...file, expiredTime })
        },
        error: err => {
          console.error('Error loading avatar:', err)
        }
      })
  }

  @DataAction()
  public async loadFiles(@Payload('id') id: string) {
    return this.backendService.getFile(id).pipe(
      tap(res => {
        const expiresValue = res.signedUrl
          .split('Expires')[1]
          .split('&')[0]
          .split('=')[1]
        const expiredTime = moment().add(expiresValue, 'seconds').toISOString()
        this.upsertOne({ ...res, expiredTime })
      })
    )
  }

  @DataAction()
  public getPccPatientImage(@Payload('id') id: string) {
    if (!id) return
    return this.backendService
      .getPccImage(id)
      .pipe(
        tap(pccCurrentPatientImage =>
          this.patchState({ pccCurrentPatientImage })
        )
      )
  }

  @DataAction()
  public createNewPatientFile(
    @Payload('data')
    data: {
      name: string
      mimeType: string
    },
    @Payload('file') pccFile: Blob
  ) {
    return this.backendService.createFile(data).pipe(
      tap(file => {
        // @ts-ignore
        this.updateCreatedFile(file.signedUrl, pccFile)
        this.patchState({ newFileId: file.id })
      })
    )
  }

  @DataAction()
  public updateCreatedFile(
    @Payload('url') url: string,
    @Payload('data') data: Blob
  ) {
    return this.backendService.updateFileBody(url, data).pipe(ignoreElements())
  }

  @DataAction()
  public logout() {
    this.patchState({
      newFileId: null,
      pccCurrentPatientImage: null
    })
  }

  protected setPaginationSetting(): Observable<any> {
    throw new Error('Method not implemented.')
  }

  protected loadEntitiesFromBackend(
    ids: string[] | undefined
  ): Observable<void> {
    throw new Error('Method not implemented.')
  }

  private checkExpiredImageAndUpdate() {
    return this.backendService.intervalUpdates$.pipe(
      tap(() => {
        if (!Object.keys(this.entities).length) {
          return
        }

        Object.values(this.entities).forEach(file => {
          if (!file.expiredTime) {
            return
          }

          if (file.expiredTime <= new Date().toISOString()) {
            this.loadFiles(file.id)
          }
        })
      })
    )
  }

  private upsertAllFiles(res: FileDTO[]) {
    this.upsertMany(res)
  }
}
