import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { LoginComponent } from './components/login/login.component'
import { NotAuthenticated } from '../../../core/guards/not-authenticated.guard'

const authRoutes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canLoad: [NotAuthenticated],
    canActivate: [NotAuthenticated],
    data: {
      title: 'login'
    }
  }
]

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
