import { FormGroup } from '@angular/forms'
import { DateTime } from 'luxon'

export const cloneObj = (obj: any): any => {
  return JSON.parse(JSON.stringify(obj))
}
export const checkFormFields = (form: FormGroup) => {
  Object.values(form.controls).forEach(control => {
    if (control.invalid) {
      control.markAsDirty()
      control.updateValueAndValidity({ onlySelf: true })
    }
  })
}
export const innitCustomScroll = (): void => {
  //does not have time to initialize house tree
  // setTimeout(
  // 	// @ts-ignore
  // 	() => Scrollbar.init(document.querySelector('.ant-table-tbody')),
  // 	0
  // )
}

export function checkManualDevice(id: string) {
  return id.toLowerCase().includes('manual')
}

export const subtractHours = (numOfHours: number, date = new Date()): Date => {
  return new Date(date.valueOf() - numOfHours * 60 * 60 * 1000)
}
export const subtractMinusHours = (
  numOfHours: number,
  date = new Date()
): Date => {
  return new Date(date.valueOf() - (numOfHours * 60 * 60 * 1000) / 2)
}
export const subtractPlusHours = (
  numOfHours: number,
  date = new Date()
): Date => {
  return new Date(date.valueOf() + (numOfHours * 60 * 60 * 1000) / 2)
}
export const innitAddProgressCustomClass = (): void => {
  //does not have time to initialize house tree
  setTimeout(() => {
    // @ts-ignore
    document.querySelector('.ant-progress').classList.add('custom-progress')
  }, 1500)
}

export const base64ToFloat32Array = (base64: string): number[] => {
  const uint8Array: Uint8Array = Uint8Array.from(atob(base64), c =>
    c.charCodeAt(0)
  )

  const dv = new DataView(uint8Array.buffer)
  const bytesPerNumber = 4
  let res = []
  for (let i = 0; i < dv.byteLength; i += bytesPerNumber) {
    res.push(dv.getFloat32(i))
  }
  return res
}

export const separator: string[] | any = (xs: string, s: number) =>
  xs.length ? [xs.slice(0, s), ...separator(xs.slice(s), s)] : []

export const dataShaping = (date: string | Date, frequency: number): string => {
  const dataShaping = new Date(date).getTime() - 1000 / frequency
  return new Date(dataShaping).toISOString()
}

export const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i)
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i)
  },
  iOS: function () {
    return (
      navigator.userAgent.match(/iPhone|iPad|iPod/i) ||
      (navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform)) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) ||
      navigator.platform === 'iPad'
    )
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i)
  },
  Windows: function () {
    return (
      navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i)
    )
  },
  any: function () {
    // @ts-ignore
    const match = window.matchMedia || window.msMatchMedia
    if (match) {
      const mq = match('(pointer:coarse)')
      return mq.matches
    }
    return false
  }
}

export const getUserLocale = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0]
  }
  return navigator.language
}
export const transformCelsiusToFahrenhei = (data: number | string) => {
  return (Number(data) * 9) / 5 + 32
}
export const transformFahrenheiToCelsius = (data: number | string) => {
  return ((Number(data) - 32) * 5) / 9
}

export const transformDataToCurrentTimeZone = (data: string) => {
  const dateTimeFormat = new Intl.DateTimeFormat()
  const userTimeZone = dateTimeFormat.resolvedOptions().timeZone
  const currentDate = DateTime.fromISO(data).setZone(userTimeZone)
  const timeString = currentDate.toISOTime()
  const dateTime = DateTime.fromISO(`1970-01-01T${timeString}`, {
    zone: 'UTC'
  })

  return dateTime.toISOTime({
    suppressMilliseconds: false,
    includeOffset: true
  })
}

export const isOlderThan = (value: string | Date, longHourAgo: number = 4) => {
  const minsAgo = longHourAgo * 60
  const now = new Date()
  const receivedValue = new Date(value)
  return (now.getTime() - receivedValue.getTime()) / 1000 / 60 > minsAgo
}

export function eqSet<T>(as: Set<T>, bs: Set<T>): boolean {
  if (as.size !== bs.size) {
    return false
  }
  for (const a of as) {
    if (!bs.has(a)) {
      return false
    }
  }
  return true
}
