import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { InputComponent } from './components/input/input.component'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { ReactiveFormsModule } from '@angular/forms'

@NgModule({
  declarations: [InputComponent],
  imports: [
    CommonModule,
    NzInputModule,
    NzIconModule,
    NzToolTipModule,
    ReactiveFormsModule
  ],
  exports: [InputComponent]
})
export class InputsModule {}
