import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  BehaviorSubject,
  map,
  Observable,
  switchMap,
  take,
  takeUntil,
  throwError
} from 'rxjs'
import { catchError, filter } from 'rxjs/operators'
import { ErrorState } from '../../store/error/error.state'
import { AuthState } from '../../store/auth/auth.state'
import { UserState } from '../../store/user/user.state'
import { DepartmentState } from '../../store/department/department.state'
import { PccState } from '../../store/pcc/pcc.state'
import { BackendService } from '../../shared/services/backend.service'

@Injectable({
  providedIn: 'root'
})
export class RequestInterceptor implements HttpInterceptor {
  private isRefreshing = false
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  )

  constructor(
    private errorState: ErrorState,
    private authState: AuthState,
    private userState: UserState,
    private departmentState: DepartmentState,
    private pccState: PccState,
    private backendService: BackendService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      takeUntil(this.backendService.logoutObservable),
      catchError((errorResponse: HttpErrorResponse) => {
        const type = errorResponse.url?.split('/')
        if (
          errorResponse.status === 401 &&
          errorResponse.error.code === 'MFA_VERIFICATION_CODE_INCORRECT'
        ) {
          this.errorState.setError(errorResponse.error)
          return throwError(errorResponse)
        } else if (
          errorResponse.url &&
          type &&
          type[type.length - 1] === 'refresh' &&
          errorResponse.status === 401
        ) {
          if (this.userState.haveCurrentUser()) {
            this.pccState.pccLogout().pipe(take(1)).subscribe()
            this.departmentState.logout()
          } else {
            this.departmentState.logout()
          }
          return throwError(errorResponse)
        } else if (errorResponse.status === 401) {
          this.isRefreshing = false
          return this.handle401Error(request, next)
        } else if (errorResponse.error.code === 'TOKEN_NOT_VALID') {
          this.errorState.setError(errorResponse.error)
          this.departmentState.logout()
          return throwError(() => errorResponse)
        } else {
          if (errorResponse.error && errorResponse.error.errors) {
            if (errorResponse.error.errors.length > 1) {
              let details: any
              errorResponse.error.errors.forEach((e: any) => {
                if (!details) details = `${e.detail}`
                else details = `${details}, ${e.detail}`
              })
              this.errorState.setError({
                ...errorResponse.error.errors[0],
                message: details
              })
            } else {
              this.errorState.setError({
                ...errorResponse.error.errors[0],
                message: errorResponse.error.errors[0].detail
              })
            }
          } else {
            this.errorState.setError(errorResponse.error)
          }
          return throwError(errorResponse)
        }
      })
    )
  }

  addAuthenticationToken(request: HttpRequest<any>, token: string) {
    if (!token) {
      return request
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true
      return this.authState.refreshAccessToken().pipe(
        takeUntil(this.backendService.logoutObservable),
        switchMap(token => {
          this.refreshTokenSubject.next(token)
          this.isRefreshing = false
          return next.handle(this.addAuthenticationToken(request, token))
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          this.errorState.setError(errorResponse.error)
          this.isRefreshing = false
          if (this.userState.haveCurrentUser()) {
            this.pccState.pccLogout().pipe(take(1)).subscribe()
            this.departmentState.logout()
          } else {
            this.departmentState.logout()
          }
          return next.handle(request)
        })
      )
    }
    return this.refreshTokenSubject.pipe(
      takeUntil(this.backendService.logoutObservable),
      filter(token => token !== null),
      take(1),
      switchMap(token =>
        next.handle(this.addAuthenticationToken(request, token))
      )
    )
  }
}
